/*
 * @Author: lich
 * @Date: 2023-03-31 20:21:32
 * @Last Modified by: lich
 * @Last Modified time: 2023-03-31 20:23:18
 * @Description: 在不同的语言下展示的时间格式不同
 */
export default defineNuxtPlugin((nuxtApp) => {
    //@TODO
});
