import { defineNuxtPlugin } from '#app/nuxt'
import { LazyDiscount, LazyRenderCacheable } from '#components'
const lazyGlobalComponents = [
  ["Discount", LazyDiscount],
["RenderCacheable", LazyRenderCacheable],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
