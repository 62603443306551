declare const washeng_tracking_v2: any;
/**
 * 解决旧版 viewer 路由重定向
 * 更好的做法 能否在 server中 直接重定向
 */
export default defineNuxtRouteMiddleware((to, from) => {
    if (process.client)
        (window as any).washeng_tracking_v2 &&
            washeng_tracking_v2.default("pv", {
                toPath: window.location.host + to.fullPath,
                fromPath: window.location.host + from.fullPath,
            });
});
