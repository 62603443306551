import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default defineNuxtPlugin(async (nuxtApp) => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const visitorId = result.visitorId;

    // Store the visitorId in localStorage or sessionStorage
    localStorage.setItem("visitorId", visitorId);
});
