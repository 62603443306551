import revive_payload_client_3CJ0IG2Nh9 from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_A8B6qr7ZDt from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_15C0bTQWzk from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_xNMYljHSpQ from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@3.2.11_vue@3.5.11/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_bv6L932bFX from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FIy3najw0i from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZmVnjJZmPJ from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_LogZQ8u4hm from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/.nuxt/components.plugin.mjs";
import prefetch_client_k4MdbTI1dB from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_r1k9hxYeVF from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_oecmthpck5lr2l5hw2uis52bii/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/.nuxt/sentry-client-config.mjs";
import strapi_Z4D0QzP89A from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/@nuxtjs+strapi@1.12.0_rollup@4.24.0/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import composition_KefhTm1OFv from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_K3poprh9LV from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_wy0B721ODc from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import fbq_tracker_McksU7yt08 from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/plugins/fbq-tracker.ts";
import runtime_config_mBPNEEOBFr from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/plugins/runtime-config.ts";
import gtag_tracker_CljMXBqHJO from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/plugins/gtag-tracker.ts";
import loadFont_KswlAe6lWK from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/plugins/loadFont.ts";
import web_vitals_client_dmrkm7tCkD from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/plugins/web-vitals.client.ts";
import error_handler_kEP5FliEXj from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/plugins/error-handler.ts";
import dayjs_pGiXRjcsJO from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/plugins/dayjs.ts";
import fingerprint_client_mCHejvGXC2 from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/plugins/fingerprint.client.ts";
import gobal_properrties_6xO9UfFtPj from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/plugins/gobal-properrties.ts";
import loadFont_client_a9H3KXUQ65 from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/plugins/loadFont.client.ts";
import vue_gtag_client_CYt6toK02h from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/plugins/vue-gtag.client.ts";
export default [
  revive_payload_client_3CJ0IG2Nh9,
  unhead_A8B6qr7ZDt,
  router_15C0bTQWzk,
  _0_siteConfig_xNMYljHSpQ,
  payload_client_bv6L932bFX,
  navigation_repaint_client_FIy3najw0i,
  check_outdated_build_client_ZmVnjJZmPJ,
  chunk_reload_client_LogZQ8u4hm,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_k4MdbTI1dB,
  sentry_client_r1k9hxYeVF,
  sentry_client_config_o34nk2sJbg,
  strapi_Z4D0QzP89A,
  composition_KefhTm1OFv,
  i18n_K3poprh9LV,
  plugin_wy0B721ODc,
  fbq_tracker_McksU7yt08,
  runtime_config_mBPNEEOBFr,
  gtag_tracker_CljMXBqHJO,
  loadFont_KswlAe6lWK,
  web_vitals_client_dmrkm7tCkD,
  error_handler_kEP5FliEXj,
  dayjs_pGiXRjcsJO,
  fingerprint_client_mCHejvGXC2,
  gobal_properrties_6xO9UfFtPj,
  loadFont_client_a9H3KXUQ65,
  vue_gtag_client_CYt6toK02h
]