import validate from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45update_45site_45config_45global from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/middleware/01.update-site-config.global.ts";
import redirect_45route_45global from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/middleware/redirect-route.global.ts";
import tracking_45global from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/middleware/tracking.global.ts";
import manifest_45route_45rule from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45update_45site_45config_45global,
  redirect_45route_45global,
  tracking_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}