// plugins/loadFont.client.ts
export default defineNuxtPlugin(() => {
    // if (process.server) return;
    // const fontUrl = document.cookie
    //     .split("; ")
    //     .find((row) => row.startsWith("fontUrl="))
    //     ?.split("=")[1];
    // if (fontUrl) {
    //     const style = document.createElement("style");
    //     style.innerHTML = `
    //         @font-face {
    //             font-family: 'DynamicFont';
    //             src: url(${fontUrl}) format('woff2');
    //             font-weight: normal;
    //             font-style: normal;
    //         }
    //         html {
    //             font-family: 'DynamicFont', sans-serif;
    //         }
    //     `;
    //     document.head.appendChild(style);
    // }
});
