import { useScriptMetaPixel, useScriptClarity, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const clarity = useScriptClarity({"id":"ltu7jgydkl"})
    const metaPixel = useScriptMetaPixel({"id":"876197524220380"})
    return { provide: { $scripts: { clarity, metaPixel } } }
  }
})