// plugins/web-vitals.client.ts

import { onCLS, onFCP, onINP, onLCP, onTTFB, type Metric } from "web-vitals";

function sendToAnalytics(metric: Metric): void {
    const visitorId = localStorage.getItem("visitorId") || "unknown";

    const { userAgent } = useDevice();

    const body = JSON.stringify({
        name: metric.name,
        value: metric.value,
        id: metric.id,
        path: window.location.pathname,
        userId: visitorId, // 使用浏览器指纹作为 userId
        sessionId: getSessionId(), //sessionStorage.getItem("sessionId") || "unknown",
        userAgent: userAgent,
        timestamp: new Date().toISOString(),
    });

    fetch("/web-vitals", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body,
    });
}

export default defineNuxtPlugin((_nuxtApp) => {
    onCLS(sendToAnalytics);
    onINP(sendToAnalytics);
    onLCP(sendToAnalytics);
    onTTFB(sendToAnalytics);
    onFCP(sendToAnalytics);
});

// 获取session id,如果没有则生成一个
function getSessionId(): string {
    let sessionId = sessionStorage.getItem("sessionId");
    if (!sessionId) {
        sessionId = Math.random().toString(36).slice(2);
        sessionStorage.setItem("sessionId", sessionId);
    }
    return sessionId;
}
