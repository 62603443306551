/** 路由重定向 */

const redirectGroup = [
    // {
    //     prev: "/comparison",
    //     later: "/",
    //     /**默认替换 replace path , 1 全替换 */
    //     mode: 1,
    // },
    // {
    //     prev: "/hardware/vs-competitors",
    //     later: "/",
    //     mode: 1,
    // },
    {
        prev: "/mirrors",
        later: "/mirror/bozee",
    },
    {
        prev: "/viewer/How-to-Use-Screen-Mirroring-by-AirPlay-on-NearHub-Borad",
        later: "/resources/help-center/how-to-use-screen-mirroring-by-airplay-on-nearhub-board",
    },
    {
        prev: "/viewer/How-to-screencast-in-AP-mode-(Hotspot)",
        later: "/resources/help-center/how-to-screencast-in-ap-mode-hotspot",
    },
    {
        prev: "/viewer/",
        later: "/blog/",
    },
    {
        prev: "/resources/blog",
        later: "/blog/",
    },
    {
        prev: "/download",
        later: "/download/nearrooms",
    },
    {
        prev: "/support",
        later: "/connect/contact-support",
    },
    {
        prev: "/academy",
        later: "/resources/academy",
    },
    {
        prev: "/app-intergrations",
        later: "/hardware/app-intergrations",
    },
    {
        prev: "/select-nearhub",
        later: "/hardware/select-nearhub",
    },
    {
        prev: "/contact-support",
        later: "/connect/contact-support",
    },
    {
        prev: "/contact-sales",
        later: "/connect/contact-sales",
    },
    {
        prev: "/home",
        later: "/",
    },
    // ----------------------------------------------
    // {
    //     prev: "blog/The-5-Best-Online-Whiteboards-to-Visualize-Thoughts-and-Ideas-in-2023",
    //     later: "blog/the-5-best-online-whiteboards-to-visualize-thoughts-and-Ideas-in-2023",
    //     // later 有问题
    // },
    {
        prev: "/blog/The-5-Best-Video-Conferencing-Software-Options-In-2023",
        later: "/blog/the-5-best-video-conferencing-software-options",
        mode: 1,
    },
    {
        prev: "/blog/how-do-i-use-screen-mirroring-by-eshare-on-nearhub-board",
        later: "/resources/help-center/how-do-i-use-screen-mirroring-by-eshare-on-nearhub-board",
        mode: 1,
    },
    {
        prev: "/blog/3-google-glassroom-games-make-your-classroom-more-attr",
        later: "/blog/3-google-glassroom-games-make-your-classroom-more-attractive",
        mode: 1,
    },
    {
        prev: "/blog/what-is-zoom-error-code-10004-and-how-to-fix-it",
        later: "/blog/zoom-error-code-10004-how-to-fix-it",
        mode: 1,
    },
    {
        prev: "/ja-JP/hardware/vs-competitors",
        later: "/hardware/vs-competitors",
    },

    {
        prev: "/home",
        later: "",
        mode: 1,
    },
    {
        prev: "/blog/hybrid-working%20%7C%206-best-collaboration-tools-for-virtual-brainstorming",
        later: "/blog/6-best-collaboration-tools-for-virtual-brainstorming",
        mode: 1,
    },
    {
        prev: "/resources/help-center/Quick-Start%20-Guide-To%20Set-Up-NearHub-Board",
        later: "/resources/help-center/quick-start-guide-to-set-up-nearhub-board",
        mode: 1,
        // /resources/help-center/quick-start%20-guide-to%20set-up-nearhub-board
    },
    {
        prev: "/product/nearHub-magicPad-s13",
        later: "/product/nearhub-magicpad-st1160",
    },
    {
        prev: "/comparison/nearHub-vs-hikvision",
        later: "/comparison/nearhub-vs-hikvision",
    },
    {
        prev: "/comparison/nearHus-vs-maxHub",
        later: "/comparison/nearhub-vs-maxhub",
    },
    {
        prev: "/comparison/nearHus-vs-jamboard",
        later: "/comparison/nearhub-vs-jamboard",
    },
    {
        prev: "/comparison/nearHub-vs-promethean",
        later: "/comparison/nearhub-vs-promethean",
    },
    {
        prev: "/resources/help-center/mearhub-release-note-v1.0.9.1",
        later: "/resources/help-center/nearhub-release-note-v1.0.9.1",
        mode: 1,
    },
    {
        prev: "/help-center",
        later: "/resources/help-center",
        mode: 1,
    },
    {
        prev: "/resources/help-center/Using-Zoom-on-NearHub-Board",
        later: "/resources/help-center/using-zoom-on-nearhub-board",
    },
    {
        prev: "/product/ms-001",
        later: "https://ergo.nearhub.us/product/single-monitor-arm",
        mode: 2,
    },
    {
        prev: "/product/ms-002",
        later: "https://ergo.nearhub.us/product/dual-monitor-arm",
        mode: 2,
    },
    {
        prev: "/product/single-monitor-arm",
        later: "https://ergo.nearhub.us/product/single-monitor-arm",
        mode: 2,
        host: "ergo.nearhub.us",
    },
    {
        prev: "/product/dual-monitor-arm",
        later: "https://ergo.nearhub.us/product/dual-monitor-arm",
        mode: 2,
        host: "ergo.nearhub.us",
    },
];

/**
 * 解决旧版 viewer 路由重定向
 * 更好的做法 能否在 server中 直接重定向
 */
export default defineNuxtRouteMiddleware((to, from) => {
    const { host } = useHost();
    if (to.fullPath.startsWith("/blog/")) {
        const lowercasePath = to.fullPath.toLowerCase();
        if (lowercasePath !== to.fullPath) {
            return navigateToProxy(lowercasePath, { redirectCode: 301 });
        }
        //  /blog/The-5-Best-Video-Conferencing-Software-Options-In-2023
    }

    const item = redirectGroup.find((item) => {
        if (item.host && item.host === host) {
            return false;
        }
        // 解决重定向地址大小写识别问题
        return to.fullPath.toUpperCase().startsWith(item.prev.toUpperCase()) && to.fullPath.startsWith(item.prev) && !to.fullPath.startsWith(item.later);
    });

    if (item) {
        let path = "";
        if (item.mode === 1) {
            path = item.later;
        } else if (item.mode === 2) {
            return navigateToProxy(item.later, { external: true, redirectCode: 301 });
        } else {
            path = to.fullPath.replace(item.prev, item.later);
        }

        return navigateToProxy(path, { redirectCode: 301 });

        // return navigateTo(item.later + path, { redirectCode: 301 });
    }

    console.log("🚀 -> navigateTo", to.fullPath);
});

function navigateToProxy(...args: any[]) {
    console.log("🚀 -> navigateTo", args[0]);
    return navigateTo(args[0], args[1]);
}
