// plugins/loadFont.ts
import { defineNuxtPlugin, useRequestURL } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
    const url = useRequestURL();
    const hostname = url.host;
    let style = "";

    if (hostname.includes("ergo")) {
        style = `
html {
    font-family: "Montserrat", Helvetica Neue, Helvetica, Arial, Microsoft Yahei, sans-serif;
    // font-family: "Inter", Helvetica Neue, Helvetica, Arial, Microsoft Yahei, sans-serif;
}
    `;
    } else {
        style = `
        html {
    // font-family: "Montserrat", Helvetica Neue, Helvetica, Arial, Microsoft Yahei, sans-serif;
    font-family: "Inter", Helvetica Neue, Helvetica, Arial, Microsoft Yahei, sans-serif;
        }
            `;
    }
    if (process.server && nuxtApp.ssrContext && nuxtApp.ssrContext.res) {
        // nuxtApp.ssrContext.res.setHeader("Content-Security-Policy", "style-src 'self' 'unsafe-inline';");
        // nuxtApp.ssrContext.res.writeHead(200, { "Content-Type": "text/html" });
        // nuxtApp.ssrContext.res.write(`<style>${style}</style>`);
        // nuxtApp.ssrContext.res.write("</head><body>");
    } else if (process.client) {
        const head = document.head || document.getElementsByTagName("head")[0];
        const styleElement = document.createElement("style");
        styleElement.type = "text/css";
        styleElement.appendChild(document.createTextNode(style));
        head.appendChild(styleElement);
    }
});
