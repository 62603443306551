import { default as category_45_91category_93qutpffNK5OMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/category-[category].vue?macro=true";
import { default as ArticleNavVC9libsbQFMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/ArticleNav.vue?macro=true";
import { default as BlogSectionE0jT4QDBXpMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/BlogSection.vue?macro=true";
import { default as BlogSectionItemtIPWtEuoLFMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/BlogSectionItem.vue?macro=true";
import { default as BreadcrumbsNfSt922rgOMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/Breadcrumbs.vue?macro=true";
import { default as indexN1HwaQ2F0TMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/index.vue?macro=true";
import { default as indexREr1OKCPuoMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/brand-certificate/index.vue?macro=true";
import { default as _91type_93Zo9EQ6U5ugMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/[type].vue?macro=true";
import { default as CompareBanner47LGy3zkOTMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/CompareBanner.vue?macro=true";
import { default as CompareDialogJchBFi9fZTMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/CompareDialog.vue?macro=true";
import { default as comparisonzUl8b6kxUUMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/comparison.vue?macro=true";
import { default as contrasta03bPILRGrMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/contrast.vue?macro=true";
import { default as contrast21GCkDmsxVRMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/contrast2.vue?macro=true";
import { default as forClassroomdhm50sN7OvMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/forClassroom.vue?macro=true";
import { default as goBeyondSamsungjzaMya2H0RMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/goBeyondSamsung.vue?macro=true";
import { default as infoDialogqu2q9WN4S9Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/infoDialog.vue?macro=true";
import { default as vLoopLtNbsRRv5rMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/loopScroll/direactive/vLoop.ts?macro=true";
import { default as LoopkpliZN8TFQMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/loopScroll/Loop.vue?macro=true";
import { default as nav4IUnwwrORMMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/nav.vue?macro=true";
import { default as nearhubInActionDYmyGGy9ynMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/nearhubInAction.vue?macro=true";
import { default as Slider2q857zrF66hMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/Slider2.vue?macro=true";
import { default as swiper2lGKdRd89GwMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/swiper2.vue?macro=true";
import { default as vs2e2bd8hHWzgMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/vs2.vue?macro=true";
import { default as workPlaceZeJW32Z1gdMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/workPlace.vue?macro=true";
import { default as workTheWaytBJoFr0lNxMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/workTheWay.vue?macro=true";
import { default as AboutBrandStoryZkCAAwwY9vMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutBrandStory.vue?macro=true";
import { default as AboutCaseHW7va9usBhMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutCase.vue?macro=true";
import { default as AboutCustomerGkzorYDPVxMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutCustomer.vue?macro=true";
import { default as AboutHelp76NXqP15gwMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutHelp.vue?macro=true";
import { default as AboutIntroqhLSwfSDxQMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutIntro.vue?macro=true";
import { default as AboutLocationsjWDvmR4xWlMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutLocations.vue?macro=true";
import { default as AboutWeValueJfLXaYGoC2Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutWeValue.vue?macro=true";
import { default as LocationsMsgk94VCVa6ykMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/LocationsMsg.vue?macro=true";
import { default as indexvkts6pLq4WMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/index.vue?macro=true";
import { default as indexl4dzOb17IzMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/brand-ambassadar/index.vue?macro=true";
import { default as splideItemVs86Dbo1ywMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/splideItem.vue?macro=true";
import { default as SupportodweUYcIf8Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/Support.vue?macro=true";
import { default as TabItemMUMsYZgybjMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/TabItem.vue?macro=true";
import { default as TabSlot9gje410TR5Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/TabSlot.vue?macro=true";
import { default as index7aLUUxD07wMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/index.vue?macro=true";
import { default as index5qsbQfQlT8Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-support/index.vue?macro=true";
import { default as index6VuaHFWzkwMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/partnership/index.vue?macro=true";
import { default as indexn3M8GS8ukaMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/privacy-statement/index.vue?macro=true";
import { default as indexLdrhnoAcDoMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/request-quote/index.vue?macro=true";
import { default as indexusw7jTr2nLMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/return-refund-policy/index.vue?macro=true";
import { default as indexcNLdLR6jdLMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/shipping-policy/index.vue?macro=true";
import { default as index1qNc7qDWvyMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/terms-of-service/index.vue?macro=true";
import { default as indexcVnzeWcvXwMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/warranty/index.vue?macro=true";
import { default as Pagination28mRq2ycjjMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/component/Pagination.vue?macro=true";
import { default as Reviewk97SsR0t6jMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/component/Review.vue?macro=true";
import { default as index2nvV3c26ktMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/index.vue?macro=true";
import { default as useReviews3Mh5J9W3kUMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/useReviews.ts?macro=true";
import { default as index0OM6UJAea1Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/download/nearrooms/index.vue?macro=true";
import { default as _91model_9347EggU45vFMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/experience/[model].vue?macro=true";
import { default as AppCard64ISwO9jlXMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/AppCard.vue?macro=true";
import { default as HxCategory6rfiCg64sLMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/HxCategory.vue?macro=true";
import { default as indexLttNwdjHS6Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/index.ts?macro=true";
import { default as index8eELe9dIU8Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/index.vue?macro=true";
import { default as AccessoriesCard6kotxxqcGuMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/AccessoriesCard.vue?macro=true";
import { default as BoardCompareCzO83m61SCMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/BoardCompare.vue?macro=true";
import { default as CommentSplideXt3JEMy9uFMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/CommentSplide.vue?macro=true";
import { default as SelectItemgaagzfTGIkMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectItem.vue?macro=true";
import { default as SelectPartnerzebuFi0LzKMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectPartner.vue?macro=true";
import { default as SelectProductle6svFUoCUMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectProduct.vue?macro=true";
import { default as tableItemPcSH5vqx2yMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/tableItem.vue?macro=true";
import { default as indexs4YaFqNNn8Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/index.vue?macro=true";
import { default as BookDemosHCzipkkfJYMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/BookDemos.vue?macro=true";
import { default as BottomCVU3pO0Z4eMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Bottom.vue?macro=true";
import { default as CardItemd438Da1VA0Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CardItem.vue?macro=true";
import { default as CarousellZklE5joNHMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Carousel.vue?macro=true";
import { default as CarouselMobilePn4RwjLZW6Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CarouselMobile.vue?macro=true";
import { default as CompareH73WB5Cmu4Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Compare.vue?macro=true";
import { default as CompareNewNcdT20vtSlMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CompareNew.vue?macro=true";
import { default as index5l99b749ndMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/index.vue?macro=true";
import { default as BannerCard458UPWLig9Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/BannerCard.vue?macro=true";
import { default as CardMulticlassXDeg4WedlrMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/CardMulticlass.vue?macro=true";
import { default as ColorfulThreeFrames0S1fd7q555Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ColorfulThreeFrames.vue?macro=true";
import { default as ContactCardM2D9wdc80dMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ContactCard.vue?macro=true";
import { default as CustomerCardEsf6WdEzFnMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/CustomerCard.vue?macro=true";
import { default as EnterprisesComment8I46TVHeZ3Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/EnterprisesComment.vue?macro=true";
import { default as FiveCardpZ9H7Qn0Y0Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/FiveCard.vue?macro=true";
import { default as index78tvJGEB1FMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/index.ts?macro=true";
import { default as IndexSectionFiveaHmj79qHqxMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionFive.vue?macro=true";
import { default as IndexSectionOneEOrc00mttqMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionOne.vue?macro=true";
import { default as IndexSectionOneV2WoQgquTeVwMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionOneV2.vue?macro=true";
import { default as IndexSectionSix7Uo98pgU8YMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionSix.vue?macro=true";
import { default as IndexSectionThreeJSzUiH2eM6Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionThree.vue?macro=true";
import { default as IndexSectionTwoFByC7ncozEMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionTwo.vue?macro=true";
import { default as IndexSectionTwoV2vsxvTrIQ7BMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionTwoV2.vue?macro=true";
import { default as PartContainer2X2RbkWAdzMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/PartContainer.vue?macro=true";
import { default as ProductCardiRPpUjCBS4Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ProductCard.vue?macro=true";
import { default as ProductShowCardNvgtb5l48oMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ProductShowCard.vue?macro=true";
import { default as SenceCardKOXuEkFzUUMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/SenceCard.vue?macro=true";
import { default as indexzAxul1aPclMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/index.vue?macro=true";
import { default as _91model_93FHjTexgc19Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/mirror/[model].vue?macro=true";
import { default as _91model_93x5VCICtqevMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/onboarding/[model].vue?macro=true";
import { default as indexFnq4LYIONNMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/payment-result/index.vue?macro=true";
import { default as _91id_93BY4XI1HPrkMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/[id].vue?macro=true";
import { default as renderPDeAJEXhzyMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/components/render.vue?macro=true";
import { default as TocET7SA1ny86Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/components/Toc.vue?macro=true";
import { default as indexNsWFiihtljMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/pricing/index.vue?macro=true";
import { default as _91model_935eWGAL8SpnMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/[model].vue?macro=true";
import { default as DownloadPDFDS2m9p36q0Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/DownloadPDF.vue?macro=true";
import { default as FeatureCardEh6QIaE8z3Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/FeatureCard.vue?macro=true";
import { default as indexPwJzUmuqSXMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/index.ts?macro=true";
import { default as ProductAdvantageSRK71J9NvrMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductAdvantage.vue?macro=true";
import { default as ProductBanner3CxkyJ52LpMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductBanner.vue?macro=true";
import { default as ProductCareProgrambdO1O2PDauMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductCareProgram.vue?macro=true";
import { default as ProductPromise92FbXV6TwBMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductPromise.vue?macro=true";
import { default as TechInfoTabdbHLW828ZjMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/TechInfoTab.vue?macro=true";
import { default as VideoCardWGQJSmnwpgMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/VideoCard.vue?macro=true";
import { default as BookDemozDQl4cj7eOMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/BookDemo.vue?macro=true";
import { default as CardTechSpecV2Nmm0uMOgeKMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/CardTechSpecV2.vue?macro=true";
import { default as ContactSaleRSvH3Jw9x5Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ContactSale.vue?macro=true";
import { default as CustomerReviewmghFWknIu2Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/customer-review/CustomerReview.vue?macro=true";
import { default as ReviewCardYmovP6QgV5Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/customer-review/ReviewCard.vue?macro=true";
import { default as CouponQkpOT0QoW8Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/Coupon.vue?macro=true";
import { default as FeatureListXcdqJkYtROMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/FeatureList.vue?macro=true";
import { default as ProductCardp8V1o70WPcMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/ProductCard.vue?macro=true";
import { default as ProductViewV2uyJM8tLG9nMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/ProductViewV2.vue?macro=true";
import { default as FeatureDetailKbeHZN0vfbMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/FeatureDetail.vue?macro=true";
import { default as IconCardJBJnXMcC7iMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/IconCard.vue?macro=true";
import { default as MessageCardsQEUnXjX7GMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/MessageCard.vue?macro=true";
import { default as PicTextCardloMcCBZgElMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/PicTextCard.vue?macro=true";
import { default as SplideCardA4rhSWetjEMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/SplideCard.vue?macro=true";
import { default as indexieorbsJ3MDMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/GuideSplide/index.vue?macro=true";
import { default as InfoTabCard34tKfJh716Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/InfoTabCard.vue?macro=true";
import { default as indexFGnakmvTSLMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/InfoTabSplide/index.vue?macro=true";
import { default as ListSplideBFyDdDDKVvMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ListSplide.vue?macro=true";
import { default as CouponRGVmHEPwZ7Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/Coupon.vue?macro=true";
import { default as FeatureListALEJkq3viuMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/FeatureList.vue?macro=true";
import { default as ProductCardUW8ay1BctfMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/ProductCard.vue?macro=true";
import { default as ProductViewV2AvPOnRxOMPMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/ProductViewV2.vue?macro=true";
import { default as indexpTAsXiXnyOMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/SlideSwiper/index.vue?macro=true";
import { default as TextPicujcYPahN1bMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/TextPic.vue?macro=true";
import { default as VideoIntroRpge7ub0Y5Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/VideoIntro.vue?macro=true";
import { default as useChatBotMtSS3vsM2BMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/useChatBot.ts?macro=true";
import { default as TutorialNavHBR5Y3bc3bMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/academy/components/TutorialNav.vue?macro=true";
import { default as indexHCZa7bTvXJMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/academy/index.vue?macro=true";
import { default as indexw3GG8nOvWLMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/buyer-faq/index.vue?macro=true";
import { default as index4S5KBUGpDVMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/download-center/index.vue?macro=true";
import { default as indexRTQDj2NRXVMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/help-center/index.vue?macro=true";
import { default as CanvassWvhS44BqqMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Canvas.vue?macro=true";
import { default as InstallGuidetccTKy7HGJMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/InstallGuide.vue?macro=true";
import { default as ResourcesoiVpkwlxfLMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Resources.vue?macro=true";
import { default as StepsIb11n21PlHMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Steps.vue?macro=true";
import { default as indexjxw8XzwqdFMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/index.vue?macro=true";
import { default as BannerlfXIeiTSbQMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/Banner.vue?macro=true";
import { default as Bottom5ETn3RS3jUMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/Bottom.vue?macro=true";
import { default as CardAdvantage5ck9UdJQCrMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/CardAdvantage.vue?macro=true";
import { default as TransformContainertPAcIdWrh7Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/TransformContainer.vue?macro=true";
import { default as indexRl2jH3rLhIMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/index.vue?macro=true";
import { default as _91model_932IGUSLbdERMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/shop/[model].vue?macro=true";
import { default as ShopSplideG0ypIHKE7lMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/shop/components/ShopSplide.vue?macro=true";
import { default as _91model_93TR38ZBPcxtMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/solutions/[model].vue?macro=true";
import { default as BlogAriticlLfwhPtatP0Meta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/components/BlogAriticl.vue?macro=true";
import { default as _91id_93vchL79PDlpMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/[id].vue?macro=true";
import { default as component_45stubNptZxHGK6cMeta } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubNptZxHGK6c } from "/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_rollup@4.24.0_sass@1.77.6_terser@5.34.1_typescript@4.9.5_vite@3.2.11/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "blog-category-category___en",
    path: "/blog/category-:category()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/category-[category].vue")
  },
  {
    name: "blog-category-category___ja-JP",
    path: "/ja-JP/blog/category-:category()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/category-[category].vue")
  },
  {
    name: "blog-category-category___de",
    path: "/de/blog/category-:category()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/category-[category].vue")
  },
  {
    name: "blog-category-category___es",
    path: "/es/blog/category-:category()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/category-[category].vue")
  },
  {
    name: "blog-components-ArticleNav___en",
    path: "/blog/components/ArticleNav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/ArticleNav.vue")
  },
  {
    name: "blog-components-ArticleNav___ja-JP",
    path: "/ja-JP/blog/components/ArticleNav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/ArticleNav.vue")
  },
  {
    name: "blog-components-ArticleNav___de",
    path: "/de/blog/components/ArticleNav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/ArticleNav.vue")
  },
  {
    name: "blog-components-ArticleNav___es",
    path: "/es/blog/components/ArticleNav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/ArticleNav.vue")
  },
  {
    name: "blog-components-BlogSection___en",
    path: "/blog/components/BlogSection",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/BlogSection.vue")
  },
  {
    name: "blog-components-BlogSection___ja-JP",
    path: "/ja-JP/blog/components/BlogSection",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/BlogSection.vue")
  },
  {
    name: "blog-components-BlogSection___de",
    path: "/de/blog/components/BlogSection",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/BlogSection.vue")
  },
  {
    name: "blog-components-BlogSection___es",
    path: "/es/blog/components/BlogSection",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/BlogSection.vue")
  },
  {
    name: "blog-components-BlogSectionItem___en",
    path: "/blog/components/BlogSectionItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/BlogSectionItem.vue")
  },
  {
    name: "blog-components-BlogSectionItem___ja-JP",
    path: "/ja-JP/blog/components/BlogSectionItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/BlogSectionItem.vue")
  },
  {
    name: "blog-components-BlogSectionItem___de",
    path: "/de/blog/components/BlogSectionItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/BlogSectionItem.vue")
  },
  {
    name: "blog-components-BlogSectionItem___es",
    path: "/es/blog/components/BlogSectionItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/BlogSectionItem.vue")
  },
  {
    name: "blog-components-Breadcrumbs___en",
    path: "/blog/components/Breadcrumbs",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/Breadcrumbs.vue")
  },
  {
    name: "blog-components-Breadcrumbs___ja-JP",
    path: "/ja-JP/blog/components/Breadcrumbs",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/Breadcrumbs.vue")
  },
  {
    name: "blog-components-Breadcrumbs___de",
    path: "/de/blog/components/Breadcrumbs",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/Breadcrumbs.vue")
  },
  {
    name: "blog-components-Breadcrumbs___es",
    path: "/es/blog/components/Breadcrumbs",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/components/Breadcrumbs.vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/index.vue")
  },
  {
    name: "blog___ja-JP",
    path: "/ja-JP/blog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/index.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/blog/index.vue")
  },
  {
    name: "brand-certificate___en",
    path: "/brand-certificate",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/brand-certificate/index.vue")
  },
  {
    name: "brand-certificate___ja-JP",
    path: "/ja-JP/brand-certificate",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/brand-certificate/index.vue")
  },
  {
    name: "brand-certificate___de",
    path: "/de/brand-certificate",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/brand-certificate/index.vue")
  },
  {
    name: "brand-certificate___es",
    path: "/es/brand-certificate",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/brand-certificate/index.vue")
  },
  {
    name: "comparison-type___en",
    path: "/comparison/:type()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/[type].vue")
  },
  {
    name: "comparison-type___ja-JP",
    path: "/ja-JP/comparison/:type()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/[type].vue")
  },
  {
    name: "comparison-type___de",
    path: "/de/comparison/:type()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/[type].vue")
  },
  {
    name: "comparison-type___es",
    path: "/es/comparison/:type()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/[type].vue")
  },
  {
    name: "comparison-components-CompareBanner___en",
    path: "/comparison/components/CompareBanner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/CompareBanner.vue")
  },
  {
    name: "comparison-components-CompareBanner___ja-JP",
    path: "/ja-JP/comparison/components/CompareBanner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/CompareBanner.vue")
  },
  {
    name: "comparison-components-CompareBanner___de",
    path: "/de/comparison/components/CompareBanner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/CompareBanner.vue")
  },
  {
    name: "comparison-components-CompareBanner___es",
    path: "/es/comparison/components/CompareBanner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/CompareBanner.vue")
  },
  {
    name: "comparison-components-CompareDialog___en",
    path: "/comparison/components/CompareDialog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/CompareDialog.vue")
  },
  {
    name: "comparison-components-CompareDialog___ja-JP",
    path: "/ja-JP/comparison/components/CompareDialog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/CompareDialog.vue")
  },
  {
    name: "comparison-components-CompareDialog___de",
    path: "/de/comparison/components/CompareDialog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/CompareDialog.vue")
  },
  {
    name: "comparison-components-CompareDialog___es",
    path: "/es/comparison/components/CompareDialog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/CompareDialog.vue")
  },
  {
    name: "comparison-components-comparison___en",
    path: "/comparison/components/comparison",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/comparison.vue")
  },
  {
    name: "comparison-components-comparison___ja-JP",
    path: "/ja-JP/comparison/components/comparison",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/comparison.vue")
  },
  {
    name: "comparison-components-comparison___de",
    path: "/de/comparison/components/comparison",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/comparison.vue")
  },
  {
    name: "comparison-components-comparison___es",
    path: "/es/comparison/components/comparison",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/comparison.vue")
  },
  {
    name: "comparison-components-contrast___en",
    path: "/comparison/components/contrast",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/contrast.vue")
  },
  {
    name: "comparison-components-contrast___ja-JP",
    path: "/ja-JP/comparison/components/contrast",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/contrast.vue")
  },
  {
    name: "comparison-components-contrast___de",
    path: "/de/comparison/components/contrast",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/contrast.vue")
  },
  {
    name: "comparison-components-contrast___es",
    path: "/es/comparison/components/contrast",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/contrast.vue")
  },
  {
    name: "comparison-components-contrast2___en",
    path: "/comparison/components/contrast2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/contrast2.vue")
  },
  {
    name: "comparison-components-contrast2___ja-JP",
    path: "/ja-JP/comparison/components/contrast2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/contrast2.vue")
  },
  {
    name: "comparison-components-contrast2___de",
    path: "/de/comparison/components/contrast2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/contrast2.vue")
  },
  {
    name: "comparison-components-contrast2___es",
    path: "/es/comparison/components/contrast2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/contrast2.vue")
  },
  {
    name: "comparison-components-forClassroom___en",
    path: "/comparison/components/forClassroom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/forClassroom.vue")
  },
  {
    name: "comparison-components-forClassroom___ja-JP",
    path: "/ja-JP/comparison/components/forClassroom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/forClassroom.vue")
  },
  {
    name: "comparison-components-forClassroom___de",
    path: "/de/comparison/components/forClassroom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/forClassroom.vue")
  },
  {
    name: "comparison-components-forClassroom___es",
    path: "/es/comparison/components/forClassroom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/forClassroom.vue")
  },
  {
    name: "comparison-components-goBeyondSamsung___en",
    path: "/comparison/components/goBeyondSamsung",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/goBeyondSamsung.vue")
  },
  {
    name: "comparison-components-goBeyondSamsung___ja-JP",
    path: "/ja-JP/comparison/components/goBeyondSamsung",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/goBeyondSamsung.vue")
  },
  {
    name: "comparison-components-goBeyondSamsung___de",
    path: "/de/comparison/components/goBeyondSamsung",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/goBeyondSamsung.vue")
  },
  {
    name: "comparison-components-goBeyondSamsung___es",
    path: "/es/comparison/components/goBeyondSamsung",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/goBeyondSamsung.vue")
  },
  {
    name: "comparison-components-infoDialog___en",
    path: "/comparison/components/infoDialog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/infoDialog.vue")
  },
  {
    name: "comparison-components-infoDialog___ja-JP",
    path: "/ja-JP/comparison/components/infoDialog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/infoDialog.vue")
  },
  {
    name: "comparison-components-infoDialog___de",
    path: "/de/comparison/components/infoDialog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/infoDialog.vue")
  },
  {
    name: "comparison-components-infoDialog___es",
    path: "/es/comparison/components/infoDialog",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/infoDialog.vue")
  },
  {
    name: "comparison-components-loopScroll-direactive-vLoop___en",
    path: "/comparison/components/loopScroll/direactive/vLoop",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/loopScroll/direactive/vLoop.ts")
  },
  {
    name: "comparison-components-loopScroll-direactive-vLoop___ja-JP",
    path: "/ja-JP/comparison/components/loopScroll/direactive/vLoop",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/loopScroll/direactive/vLoop.ts")
  },
  {
    name: "comparison-components-loopScroll-direactive-vLoop___de",
    path: "/de/comparison/components/loopScroll/direactive/vLoop",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/loopScroll/direactive/vLoop.ts")
  },
  {
    name: "comparison-components-loopScroll-direactive-vLoop___es",
    path: "/es/comparison/components/loopScroll/direactive/vLoop",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/loopScroll/direactive/vLoop.ts")
  },
  {
    name: "comparison-components-loopScroll-Loop___en",
    path: "/comparison/components/loopScroll/Loop",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/loopScroll/Loop.vue")
  },
  {
    name: "comparison-components-loopScroll-Loop___ja-JP",
    path: "/ja-JP/comparison/components/loopScroll/Loop",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/loopScroll/Loop.vue")
  },
  {
    name: "comparison-components-loopScroll-Loop___de",
    path: "/de/comparison/components/loopScroll/Loop",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/loopScroll/Loop.vue")
  },
  {
    name: "comparison-components-loopScroll-Loop___es",
    path: "/es/comparison/components/loopScroll/Loop",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/loopScroll/Loop.vue")
  },
  {
    name: "comparison-components-nav___en",
    path: "/comparison/components/nav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/nav.vue")
  },
  {
    name: "comparison-components-nav___ja-JP",
    path: "/ja-JP/comparison/components/nav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/nav.vue")
  },
  {
    name: "comparison-components-nav___de",
    path: "/de/comparison/components/nav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/nav.vue")
  },
  {
    name: "comparison-components-nav___es",
    path: "/es/comparison/components/nav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/nav.vue")
  },
  {
    name: "comparison-components-nearhubInAction___en",
    path: "/comparison/components/nearhubInAction",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/nearhubInAction.vue")
  },
  {
    name: "comparison-components-nearhubInAction___ja-JP",
    path: "/ja-JP/comparison/components/nearhubInAction",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/nearhubInAction.vue")
  },
  {
    name: "comparison-components-nearhubInAction___de",
    path: "/de/comparison/components/nearhubInAction",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/nearhubInAction.vue")
  },
  {
    name: "comparison-components-nearhubInAction___es",
    path: "/es/comparison/components/nearhubInAction",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/nearhubInAction.vue")
  },
  {
    name: "comparison-components-Slider2___en",
    path: "/comparison/components/Slider2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/Slider2.vue")
  },
  {
    name: "comparison-components-Slider2___ja-JP",
    path: "/ja-JP/comparison/components/Slider2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/Slider2.vue")
  },
  {
    name: "comparison-components-Slider2___de",
    path: "/de/comparison/components/Slider2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/Slider2.vue")
  },
  {
    name: "comparison-components-Slider2___es",
    path: "/es/comparison/components/Slider2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/Slider2.vue")
  },
  {
    name: "comparison-components-swiper2___en",
    path: "/comparison/components/swiper2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/swiper2.vue")
  },
  {
    name: "comparison-components-swiper2___ja-JP",
    path: "/ja-JP/comparison/components/swiper2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/swiper2.vue")
  },
  {
    name: "comparison-components-swiper2___de",
    path: "/de/comparison/components/swiper2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/swiper2.vue")
  },
  {
    name: "comparison-components-swiper2___es",
    path: "/es/comparison/components/swiper2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/swiper2.vue")
  },
  {
    name: "comparison-components-vs2___en",
    path: "/comparison/components/vs2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/vs2.vue")
  },
  {
    name: "comparison-components-vs2___ja-JP",
    path: "/ja-JP/comparison/components/vs2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/vs2.vue")
  },
  {
    name: "comparison-components-vs2___de",
    path: "/de/comparison/components/vs2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/vs2.vue")
  },
  {
    name: "comparison-components-vs2___es",
    path: "/es/comparison/components/vs2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/vs2.vue")
  },
  {
    name: "comparison-components-workPlace___en",
    path: "/comparison/components/workPlace",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/workPlace.vue")
  },
  {
    name: "comparison-components-workPlace___ja-JP",
    path: "/ja-JP/comparison/components/workPlace",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/workPlace.vue")
  },
  {
    name: "comparison-components-workPlace___de",
    path: "/de/comparison/components/workPlace",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/workPlace.vue")
  },
  {
    name: "comparison-components-workPlace___es",
    path: "/es/comparison/components/workPlace",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/workPlace.vue")
  },
  {
    name: "comparison-components-workTheWay___en",
    path: "/comparison/components/workTheWay",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/workTheWay.vue")
  },
  {
    name: "comparison-components-workTheWay___ja-JP",
    path: "/ja-JP/comparison/components/workTheWay",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/workTheWay.vue")
  },
  {
    name: "comparison-components-workTheWay___de",
    path: "/de/comparison/components/workTheWay",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/workTheWay.vue")
  },
  {
    name: "comparison-components-workTheWay___es",
    path: "/es/comparison/components/workTheWay",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/comparison/components/workTheWay.vue")
  },
  {
    name: "connect-about-us-components-AboutBrandStory___en",
    path: "/connect/about-us/components/AboutBrandStory",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutBrandStory.vue")
  },
  {
    name: "connect-about-us-components-AboutBrandStory___ja-JP",
    path: "/ja-JP/connect/about-us/components/AboutBrandStory",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutBrandStory.vue")
  },
  {
    name: "connect-about-us-components-AboutBrandStory___de",
    path: "/de/connect/about-us/components/AboutBrandStory",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutBrandStory.vue")
  },
  {
    name: "connect-about-us-components-AboutBrandStory___es",
    path: "/es/connect/about-us/components/AboutBrandStory",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutBrandStory.vue")
  },
  {
    name: "connect-about-us-components-AboutCase___en",
    path: "/connect/about-us/components/AboutCase",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutCase.vue")
  },
  {
    name: "connect-about-us-components-AboutCase___ja-JP",
    path: "/ja-JP/connect/about-us/components/AboutCase",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutCase.vue")
  },
  {
    name: "connect-about-us-components-AboutCase___de",
    path: "/de/connect/about-us/components/AboutCase",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutCase.vue")
  },
  {
    name: "connect-about-us-components-AboutCase___es",
    path: "/es/connect/about-us/components/AboutCase",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutCase.vue")
  },
  {
    name: "connect-about-us-components-AboutCustomer___en",
    path: "/connect/about-us/components/AboutCustomer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutCustomer.vue")
  },
  {
    name: "connect-about-us-components-AboutCustomer___ja-JP",
    path: "/ja-JP/connect/about-us/components/AboutCustomer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutCustomer.vue")
  },
  {
    name: "connect-about-us-components-AboutCustomer___de",
    path: "/de/connect/about-us/components/AboutCustomer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutCustomer.vue")
  },
  {
    name: "connect-about-us-components-AboutCustomer___es",
    path: "/es/connect/about-us/components/AboutCustomer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutCustomer.vue")
  },
  {
    name: "connect-about-us-components-AboutHelp___en",
    path: "/connect/about-us/components/AboutHelp",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutHelp.vue")
  },
  {
    name: "connect-about-us-components-AboutHelp___ja-JP",
    path: "/ja-JP/connect/about-us/components/AboutHelp",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutHelp.vue")
  },
  {
    name: "connect-about-us-components-AboutHelp___de",
    path: "/de/connect/about-us/components/AboutHelp",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutHelp.vue")
  },
  {
    name: "connect-about-us-components-AboutHelp___es",
    path: "/es/connect/about-us/components/AboutHelp",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutHelp.vue")
  },
  {
    name: "connect-about-us-components-AboutIntro___en",
    path: "/connect/about-us/components/AboutIntro",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutIntro.vue")
  },
  {
    name: "connect-about-us-components-AboutIntro___ja-JP",
    path: "/ja-JP/connect/about-us/components/AboutIntro",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutIntro.vue")
  },
  {
    name: "connect-about-us-components-AboutIntro___de",
    path: "/de/connect/about-us/components/AboutIntro",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutIntro.vue")
  },
  {
    name: "connect-about-us-components-AboutIntro___es",
    path: "/es/connect/about-us/components/AboutIntro",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutIntro.vue")
  },
  {
    name: "connect-about-us-components-AboutLocations___en",
    path: "/connect/about-us/components/AboutLocations",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutLocations.vue")
  },
  {
    name: "connect-about-us-components-AboutLocations___ja-JP",
    path: "/ja-JP/connect/about-us/components/AboutLocations",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutLocations.vue")
  },
  {
    name: "connect-about-us-components-AboutLocations___de",
    path: "/de/connect/about-us/components/AboutLocations",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutLocations.vue")
  },
  {
    name: "connect-about-us-components-AboutLocations___es",
    path: "/es/connect/about-us/components/AboutLocations",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutLocations.vue")
  },
  {
    name: "connect-about-us-components-AboutWeValue___en",
    path: "/connect/about-us/components/AboutWeValue",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutWeValue.vue")
  },
  {
    name: "connect-about-us-components-AboutWeValue___ja-JP",
    path: "/ja-JP/connect/about-us/components/AboutWeValue",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutWeValue.vue")
  },
  {
    name: "connect-about-us-components-AboutWeValue___de",
    path: "/de/connect/about-us/components/AboutWeValue",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutWeValue.vue")
  },
  {
    name: "connect-about-us-components-AboutWeValue___es",
    path: "/es/connect/about-us/components/AboutWeValue",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/AboutWeValue.vue")
  },
  {
    name: "connect-about-us-components-LocationsMsg___en",
    path: "/connect/about-us/components/LocationsMsg",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/LocationsMsg.vue")
  },
  {
    name: "connect-about-us-components-LocationsMsg___ja-JP",
    path: "/ja-JP/connect/about-us/components/LocationsMsg",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/LocationsMsg.vue")
  },
  {
    name: "connect-about-us-components-LocationsMsg___de",
    path: "/de/connect/about-us/components/LocationsMsg",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/LocationsMsg.vue")
  },
  {
    name: "connect-about-us-components-LocationsMsg___es",
    path: "/es/connect/about-us/components/LocationsMsg",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/components/LocationsMsg.vue")
  },
  {
    name: "connect-about-us___en",
    path: "/connect/about-us",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/index.vue")
  },
  {
    name: "connect-about-us___ja-JP",
    path: "/ja-JP/connect/about-us",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/index.vue")
  },
  {
    name: "connect-about-us___de",
    path: "/de/connect/about-us",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/index.vue")
  },
  {
    name: "connect-about-us___es",
    path: "/es/connect/about-us",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/about-us/index.vue")
  },
  {
    name: "connect-brand-ambassadar___en",
    path: "/connect/brand-ambassadar",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/brand-ambassadar/index.vue")
  },
  {
    name: "connect-brand-ambassadar___ja-JP",
    path: "/ja-JP/connect/brand-ambassadar",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/brand-ambassadar/index.vue")
  },
  {
    name: "connect-brand-ambassadar___de",
    path: "/de/connect/brand-ambassadar",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/brand-ambassadar/index.vue")
  },
  {
    name: "connect-brand-ambassadar___es",
    path: "/es/connect/brand-ambassadar",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/brand-ambassadar/index.vue")
  },
  {
    name: "connect-contact-sales-components-splideItem___en",
    path: "/connect/contact-sales/components/splideItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/splideItem.vue")
  },
  {
    name: "connect-contact-sales-components-splideItem___ja-JP",
    path: "/ja-JP/connect/contact-sales/components/splideItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/splideItem.vue")
  },
  {
    name: "connect-contact-sales-components-splideItem___de",
    path: "/de/connect/contact-sales/components/splideItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/splideItem.vue")
  },
  {
    name: "connect-contact-sales-components-splideItem___es",
    path: "/es/connect/contact-sales/components/splideItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/splideItem.vue")
  },
  {
    name: "connect-contact-sales-components-Support___en",
    path: "/connect/contact-sales/components/Support",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/Support.vue")
  },
  {
    name: "connect-contact-sales-components-Support___ja-JP",
    path: "/ja-JP/connect/contact-sales/components/Support",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/Support.vue")
  },
  {
    name: "connect-contact-sales-components-Support___de",
    path: "/de/connect/contact-sales/components/Support",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/Support.vue")
  },
  {
    name: "connect-contact-sales-components-Support___es",
    path: "/es/connect/contact-sales/components/Support",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/Support.vue")
  },
  {
    name: "connect-contact-sales-components-TabItem___en",
    path: "/connect/contact-sales/components/TabItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/TabItem.vue")
  },
  {
    name: "connect-contact-sales-components-TabItem___ja-JP",
    path: "/ja-JP/connect/contact-sales/components/TabItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/TabItem.vue")
  },
  {
    name: "connect-contact-sales-components-TabItem___de",
    path: "/de/connect/contact-sales/components/TabItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/TabItem.vue")
  },
  {
    name: "connect-contact-sales-components-TabItem___es",
    path: "/es/connect/contact-sales/components/TabItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/TabItem.vue")
  },
  {
    name: "connect-contact-sales-components-TabSlot___en",
    path: "/connect/contact-sales/components/TabSlot",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/TabSlot.vue")
  },
  {
    name: "connect-contact-sales-components-TabSlot___ja-JP",
    path: "/ja-JP/connect/contact-sales/components/TabSlot",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/TabSlot.vue")
  },
  {
    name: "connect-contact-sales-components-TabSlot___de",
    path: "/de/connect/contact-sales/components/TabSlot",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/TabSlot.vue")
  },
  {
    name: "connect-contact-sales-components-TabSlot___es",
    path: "/es/connect/contact-sales/components/TabSlot",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/components/TabSlot.vue")
  },
  {
    name: "connect-contact-sales___en",
    path: "/connect/contact-sales",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/index.vue")
  },
  {
    name: "connect-contact-sales___ja-JP",
    path: "/ja-JP/connect/contact-sales",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/index.vue")
  },
  {
    name: "connect-contact-sales___de",
    path: "/de/connect/contact-sales",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/index.vue")
  },
  {
    name: "connect-contact-sales___es",
    path: "/es/connect/contact-sales",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-sales/index.vue")
  },
  {
    name: "connect-contact-support___en",
    path: "/connect/contact-support",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-support/index.vue")
  },
  {
    name: "connect-contact-support___ja-JP",
    path: "/ja-JP/connect/contact-support",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-support/index.vue")
  },
  {
    name: "connect-contact-support___de",
    path: "/de/connect/contact-support",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-support/index.vue")
  },
  {
    name: "connect-contact-support___es",
    path: "/es/connect/contact-support",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/contact-support/index.vue")
  },
  {
    name: "connect-partnership___en",
    path: "/connect/partnership",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/partnership/index.vue")
  },
  {
    name: "connect-partnership___ja-JP",
    path: "/ja-JP/connect/partnership",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/partnership/index.vue")
  },
  {
    name: "connect-partnership___de",
    path: "/de/connect/partnership",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/partnership/index.vue")
  },
  {
    name: "connect-partnership___es",
    path: "/es/connect/partnership",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/partnership/index.vue")
  },
  {
    name: "connect-privacy-statement___en",
    path: "/connect/privacy-statement",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/privacy-statement/index.vue")
  },
  {
    name: "connect-privacy-statement___ja-JP",
    path: "/ja-JP/connect/privacy-statement",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/privacy-statement/index.vue")
  },
  {
    name: "connect-privacy-statement___de",
    path: "/de/connect/privacy-statement",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/privacy-statement/index.vue")
  },
  {
    name: "connect-privacy-statement___es",
    path: "/es/connect/privacy-statement",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/privacy-statement/index.vue")
  },
  {
    name: "connect-request-quote___en",
    path: "/connect/request-quote",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/request-quote/index.vue")
  },
  {
    name: "connect-request-quote___ja-JP",
    path: "/ja-JP/connect/request-quote",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/request-quote/index.vue")
  },
  {
    name: "connect-request-quote___de",
    path: "/de/connect/request-quote",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/request-quote/index.vue")
  },
  {
    name: "connect-request-quote___es",
    path: "/es/connect/request-quote",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/request-quote/index.vue")
  },
  {
    name: "connect-return-refund-policy___en",
    path: "/connect/return-refund-policy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/return-refund-policy/index.vue")
  },
  {
    name: "connect-return-refund-policy___ja-JP",
    path: "/ja-JP/connect/return-refund-policy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/return-refund-policy/index.vue")
  },
  {
    name: "connect-return-refund-policy___de",
    path: "/de/connect/return-refund-policy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/return-refund-policy/index.vue")
  },
  {
    name: "connect-return-refund-policy___es",
    path: "/es/connect/return-refund-policy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/return-refund-policy/index.vue")
  },
  {
    name: "connect-shipping-policy___en",
    path: "/connect/shipping-policy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/shipping-policy/index.vue")
  },
  {
    name: "connect-shipping-policy___ja-JP",
    path: "/ja-JP/connect/shipping-policy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/shipping-policy/index.vue")
  },
  {
    name: "connect-shipping-policy___de",
    path: "/de/connect/shipping-policy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/shipping-policy/index.vue")
  },
  {
    name: "connect-shipping-policy___es",
    path: "/es/connect/shipping-policy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/shipping-policy/index.vue")
  },
  {
    name: "connect-terms-of-service___en",
    path: "/connect/terms-of-service",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/terms-of-service/index.vue")
  },
  {
    name: "connect-terms-of-service___ja-JP",
    path: "/ja-JP/connect/terms-of-service",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/terms-of-service/index.vue")
  },
  {
    name: "connect-terms-of-service___de",
    path: "/de/connect/terms-of-service",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/terms-of-service/index.vue")
  },
  {
    name: "connect-terms-of-service___es",
    path: "/es/connect/terms-of-service",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/terms-of-service/index.vue")
  },
  {
    name: "connect-warranty___en",
    path: "/connect/warranty",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/warranty/index.vue")
  },
  {
    name: "connect-warranty___ja-JP",
    path: "/ja-JP/connect/warranty",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/warranty/index.vue")
  },
  {
    name: "connect-warranty___de",
    path: "/de/connect/warranty",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/warranty/index.vue")
  },
  {
    name: "connect-warranty___es",
    path: "/es/connect/warranty",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/connect/warranty/index.vue")
  },
  {
    name: "customer-reviews-component-Pagination___en",
    path: "/customer-reviews/component/Pagination",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/component/Pagination.vue")
  },
  {
    name: "customer-reviews-component-Pagination___ja-JP",
    path: "/ja-JP/customer-reviews/component/Pagination",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/component/Pagination.vue")
  },
  {
    name: "customer-reviews-component-Pagination___de",
    path: "/de/customer-reviews/component/Pagination",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/component/Pagination.vue")
  },
  {
    name: "customer-reviews-component-Pagination___es",
    path: "/es/customer-reviews/component/Pagination",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/component/Pagination.vue")
  },
  {
    name: "customer-reviews-component-Review___en",
    path: "/customer-reviews/component/Review",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/component/Review.vue")
  },
  {
    name: "customer-reviews-component-Review___ja-JP",
    path: "/ja-JP/customer-reviews/component/Review",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/component/Review.vue")
  },
  {
    name: "customer-reviews-component-Review___de",
    path: "/de/customer-reviews/component/Review",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/component/Review.vue")
  },
  {
    name: "customer-reviews-component-Review___es",
    path: "/es/customer-reviews/component/Review",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/component/Review.vue")
  },
  {
    name: "customer-reviews___en",
    path: "/customer-reviews",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/index.vue")
  },
  {
    name: "customer-reviews___ja-JP",
    path: "/ja-JP/customer-reviews",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/index.vue")
  },
  {
    name: "customer-reviews___de",
    path: "/de/customer-reviews",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/index.vue")
  },
  {
    name: "customer-reviews___es",
    path: "/es/customer-reviews",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/index.vue")
  },
  {
    name: "customer-reviews-useReviews___en",
    path: "/customer-reviews/useReviews",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/useReviews.ts")
  },
  {
    name: "customer-reviews-useReviews___ja-JP",
    path: "/ja-JP/customer-reviews/useReviews",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/useReviews.ts")
  },
  {
    name: "customer-reviews-useReviews___de",
    path: "/de/customer-reviews/useReviews",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/useReviews.ts")
  },
  {
    name: "customer-reviews-useReviews___es",
    path: "/es/customer-reviews/useReviews",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/customer-reviews/useReviews.ts")
  },
  {
    name: "download-nearrooms___en",
    path: "/download/nearrooms",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/download/nearrooms/index.vue")
  },
  {
    name: "download-nearrooms___ja-JP",
    path: "/ja-JP/download/nearrooms",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/download/nearrooms/index.vue")
  },
  {
    name: "download-nearrooms___de",
    path: "/de/download/nearrooms",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/download/nearrooms/index.vue")
  },
  {
    name: "download-nearrooms___es",
    path: "/es/download/nearrooms",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/download/nearrooms/index.vue")
  },
  {
    name: "experience-model___en",
    path: "/experience/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/experience/[model].vue")
  },
  {
    name: "experience-model___ja-JP",
    path: "/ja-JP/experience/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/experience/[model].vue")
  },
  {
    name: "experience-model___de",
    path: "/de/experience/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/experience/[model].vue")
  },
  {
    name: "experience-model___es",
    path: "/es/experience/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/experience/[model].vue")
  },
  {
    name: "hardware-app-intergrations-components-AppCard___en",
    path: "/hardware/app-intergrations/components/AppCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/AppCard.vue")
  },
  {
    name: "hardware-app-intergrations-components-AppCard___ja-JP",
    path: "/ja-JP/hardware/app-intergrations/components/AppCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/AppCard.vue")
  },
  {
    name: "hardware-app-intergrations-components-AppCard___de",
    path: "/de/hardware/app-intergrations/components/AppCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/AppCard.vue")
  },
  {
    name: "hardware-app-intergrations-components-AppCard___es",
    path: "/es/hardware/app-intergrations/components/AppCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/AppCard.vue")
  },
  {
    name: "hardware-app-intergrations-components-HxCategory___en",
    path: "/hardware/app-intergrations/components/HxCategory",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/HxCategory.vue")
  },
  {
    name: "hardware-app-intergrations-components-HxCategory___ja-JP",
    path: "/ja-JP/hardware/app-intergrations/components/HxCategory",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/HxCategory.vue")
  },
  {
    name: "hardware-app-intergrations-components-HxCategory___de",
    path: "/de/hardware/app-intergrations/components/HxCategory",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/HxCategory.vue")
  },
  {
    name: "hardware-app-intergrations-components-HxCategory___es",
    path: "/es/hardware/app-intergrations/components/HxCategory",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/HxCategory.vue")
  },
  {
    name: "hardware-app-intergrations-components___en",
    path: "/hardware/app-intergrations/components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/index.ts")
  },
  {
    name: "hardware-app-intergrations-components___ja-JP",
    path: "/ja-JP/hardware/app-intergrations/components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/index.ts")
  },
  {
    name: "hardware-app-intergrations-components___de",
    path: "/de/hardware/app-intergrations/components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/index.ts")
  },
  {
    name: "hardware-app-intergrations-components___es",
    path: "/es/hardware/app-intergrations/components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/components/index.ts")
  },
  {
    name: "hardware-app-intergrations___en",
    path: "/hardware/app-intergrations",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/index.vue")
  },
  {
    name: "hardware-app-intergrations___ja-JP",
    path: "/ja-JP/hardware/app-intergrations",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/index.vue")
  },
  {
    name: "hardware-app-intergrations___de",
    path: "/de/hardware/app-intergrations",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/index.vue")
  },
  {
    name: "hardware-app-intergrations___es",
    path: "/es/hardware/app-intergrations",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/app-intergrations/index.vue")
  },
  {
    name: "hardware-select-nearhub-components-AccessoriesCard___en",
    path: "/hardware/select-nearhub/components/AccessoriesCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/AccessoriesCard.vue")
  },
  {
    name: "hardware-select-nearhub-components-AccessoriesCard___ja-JP",
    path: "/ja-JP/hardware/select-nearhub/components/AccessoriesCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/AccessoriesCard.vue")
  },
  {
    name: "hardware-select-nearhub-components-AccessoriesCard___de",
    path: "/de/hardware/select-nearhub/components/AccessoriesCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/AccessoriesCard.vue")
  },
  {
    name: "hardware-select-nearhub-components-AccessoriesCard___es",
    path: "/es/hardware/select-nearhub/components/AccessoriesCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/AccessoriesCard.vue")
  },
  {
    name: "hardware-select-nearhub-components-BoardCompare___en",
    path: "/hardware/select-nearhub/components/BoardCompare",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/BoardCompare.vue")
  },
  {
    name: "hardware-select-nearhub-components-BoardCompare___ja-JP",
    path: "/ja-JP/hardware/select-nearhub/components/BoardCompare",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/BoardCompare.vue")
  },
  {
    name: "hardware-select-nearhub-components-BoardCompare___de",
    path: "/de/hardware/select-nearhub/components/BoardCompare",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/BoardCompare.vue")
  },
  {
    name: "hardware-select-nearhub-components-BoardCompare___es",
    path: "/es/hardware/select-nearhub/components/BoardCompare",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/BoardCompare.vue")
  },
  {
    name: "hardware-select-nearhub-components-CommentSplide___en",
    path: "/hardware/select-nearhub/components/CommentSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/CommentSplide.vue")
  },
  {
    name: "hardware-select-nearhub-components-CommentSplide___ja-JP",
    path: "/ja-JP/hardware/select-nearhub/components/CommentSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/CommentSplide.vue")
  },
  {
    name: "hardware-select-nearhub-components-CommentSplide___de",
    path: "/de/hardware/select-nearhub/components/CommentSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/CommentSplide.vue")
  },
  {
    name: "hardware-select-nearhub-components-CommentSplide___es",
    path: "/es/hardware/select-nearhub/components/CommentSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/CommentSplide.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectItem___en",
    path: "/hardware/select-nearhub/components/SelectItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectItem.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectItem___ja-JP",
    path: "/ja-JP/hardware/select-nearhub/components/SelectItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectItem.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectItem___de",
    path: "/de/hardware/select-nearhub/components/SelectItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectItem.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectItem___es",
    path: "/es/hardware/select-nearhub/components/SelectItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectItem.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectPartner___en",
    path: "/hardware/select-nearhub/components/SelectPartner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectPartner.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectPartner___ja-JP",
    path: "/ja-JP/hardware/select-nearhub/components/SelectPartner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectPartner.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectPartner___de",
    path: "/de/hardware/select-nearhub/components/SelectPartner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectPartner.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectPartner___es",
    path: "/es/hardware/select-nearhub/components/SelectPartner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectPartner.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectProduct___en",
    path: "/hardware/select-nearhub/components/SelectProduct",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectProduct.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectProduct___ja-JP",
    path: "/ja-JP/hardware/select-nearhub/components/SelectProduct",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectProduct.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectProduct___de",
    path: "/de/hardware/select-nearhub/components/SelectProduct",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectProduct.vue")
  },
  {
    name: "hardware-select-nearhub-components-SelectProduct___es",
    path: "/es/hardware/select-nearhub/components/SelectProduct",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/SelectProduct.vue")
  },
  {
    name: "hardware-select-nearhub-components-tableItem___en",
    path: "/hardware/select-nearhub/components/tableItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/tableItem.vue")
  },
  {
    name: "hardware-select-nearhub-components-tableItem___ja-JP",
    path: "/ja-JP/hardware/select-nearhub/components/tableItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/tableItem.vue")
  },
  {
    name: "hardware-select-nearhub-components-tableItem___de",
    path: "/de/hardware/select-nearhub/components/tableItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/tableItem.vue")
  },
  {
    name: "hardware-select-nearhub-components-tableItem___es",
    path: "/es/hardware/select-nearhub/components/tableItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/components/tableItem.vue")
  },
  {
    name: "hardware-select-nearhub___en",
    path: "/hardware/select-nearhub",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/index.vue")
  },
  {
    name: "hardware-select-nearhub___ja-JP",
    path: "/ja-JP/hardware/select-nearhub",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/index.vue")
  },
  {
    name: "hardware-select-nearhub___de",
    path: "/de/hardware/select-nearhub",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/index.vue")
  },
  {
    name: "hardware-select-nearhub___es",
    path: "/es/hardware/select-nearhub",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/select-nearhub/index.vue")
  },
  {
    name: "hardware-vs-competitors-components-BookDemos___en",
    path: "/hardware/vs-competitors/components/BookDemos",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/BookDemos.vue")
  },
  {
    name: "hardware-vs-competitors-components-BookDemos___ja-JP",
    path: "/ja-JP/hardware/vs-competitors/components/BookDemos",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/BookDemos.vue")
  },
  {
    name: "hardware-vs-competitors-components-BookDemos___de",
    path: "/de/hardware/vs-competitors/components/BookDemos",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/BookDemos.vue")
  },
  {
    name: "hardware-vs-competitors-components-BookDemos___es",
    path: "/es/hardware/vs-competitors/components/BookDemos",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/BookDemos.vue")
  },
  {
    name: "hardware-vs-competitors-components-Bottom___en",
    path: "/hardware/vs-competitors/components/Bottom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Bottom.vue")
  },
  {
    name: "hardware-vs-competitors-components-Bottom___ja-JP",
    path: "/ja-JP/hardware/vs-competitors/components/Bottom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Bottom.vue")
  },
  {
    name: "hardware-vs-competitors-components-Bottom___de",
    path: "/de/hardware/vs-competitors/components/Bottom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Bottom.vue")
  },
  {
    name: "hardware-vs-competitors-components-Bottom___es",
    path: "/es/hardware/vs-competitors/components/Bottom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Bottom.vue")
  },
  {
    name: "hardware-vs-competitors-components-CardItem___en",
    path: "/hardware/vs-competitors/components/CardItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CardItem.vue")
  },
  {
    name: "hardware-vs-competitors-components-CardItem___ja-JP",
    path: "/ja-JP/hardware/vs-competitors/components/CardItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CardItem.vue")
  },
  {
    name: "hardware-vs-competitors-components-CardItem___de",
    path: "/de/hardware/vs-competitors/components/CardItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CardItem.vue")
  },
  {
    name: "hardware-vs-competitors-components-CardItem___es",
    path: "/es/hardware/vs-competitors/components/CardItem",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CardItem.vue")
  },
  {
    name: "hardware-vs-competitors-components-Carousel___en",
    path: "/hardware/vs-competitors/components/Carousel",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Carousel.vue")
  },
  {
    name: "hardware-vs-competitors-components-Carousel___ja-JP",
    path: "/ja-JP/hardware/vs-competitors/components/Carousel",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Carousel.vue")
  },
  {
    name: "hardware-vs-competitors-components-Carousel___de",
    path: "/de/hardware/vs-competitors/components/Carousel",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Carousel.vue")
  },
  {
    name: "hardware-vs-competitors-components-Carousel___es",
    path: "/es/hardware/vs-competitors/components/Carousel",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Carousel.vue")
  },
  {
    name: "hardware-vs-competitors-components-CarouselMobile___en",
    path: "/hardware/vs-competitors/components/CarouselMobile",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CarouselMobile.vue")
  },
  {
    name: "hardware-vs-competitors-components-CarouselMobile___ja-JP",
    path: "/ja-JP/hardware/vs-competitors/components/CarouselMobile",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CarouselMobile.vue")
  },
  {
    name: "hardware-vs-competitors-components-CarouselMobile___de",
    path: "/de/hardware/vs-competitors/components/CarouselMobile",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CarouselMobile.vue")
  },
  {
    name: "hardware-vs-competitors-components-CarouselMobile___es",
    path: "/es/hardware/vs-competitors/components/CarouselMobile",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CarouselMobile.vue")
  },
  {
    name: "hardware-vs-competitors-components-Compare___en",
    path: "/hardware/vs-competitors/components/Compare",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Compare.vue")
  },
  {
    name: "hardware-vs-competitors-components-Compare___ja-JP",
    path: "/ja-JP/hardware/vs-competitors/components/Compare",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Compare.vue")
  },
  {
    name: "hardware-vs-competitors-components-Compare___de",
    path: "/de/hardware/vs-competitors/components/Compare",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Compare.vue")
  },
  {
    name: "hardware-vs-competitors-components-Compare___es",
    path: "/es/hardware/vs-competitors/components/Compare",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/Compare.vue")
  },
  {
    name: "hardware-vs-competitors-components-CompareNew___en",
    path: "/hardware/vs-competitors/components/CompareNew",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CompareNew.vue")
  },
  {
    name: "hardware-vs-competitors-components-CompareNew___ja-JP",
    path: "/ja-JP/hardware/vs-competitors/components/CompareNew",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CompareNew.vue")
  },
  {
    name: "hardware-vs-competitors-components-CompareNew___de",
    path: "/de/hardware/vs-competitors/components/CompareNew",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CompareNew.vue")
  },
  {
    name: "hardware-vs-competitors-components-CompareNew___es",
    path: "/es/hardware/vs-competitors/components/CompareNew",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/components/CompareNew.vue")
  },
  {
    name: "hardware-vs-competitors___en",
    path: "/hardware/vs-competitors",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/index.vue")
  },
  {
    name: "hardware-vs-competitors___ja-JP",
    path: "/ja-JP/hardware/vs-competitors",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/index.vue")
  },
  {
    name: "hardware-vs-competitors___de",
    path: "/de/hardware/vs-competitors",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/index.vue")
  },
  {
    name: "hardware-vs-competitors___es",
    path: "/es/hardware/vs-competitors",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/hardware/vs-competitors/index.vue")
  },
  {
    name: "index-components-BannerCard___en",
    path: "//components/BannerCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/BannerCard.vue")
  },
  {
    name: "index-components-BannerCard___ja-JP",
    path: "/ja-JP//components/BannerCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/BannerCard.vue")
  },
  {
    name: "index-components-BannerCard___de",
    path: "/de//components/BannerCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/BannerCard.vue")
  },
  {
    name: "index-components-BannerCard___es",
    path: "/es//components/BannerCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/BannerCard.vue")
  },
  {
    name: "index-components-CardMulticlass___en",
    path: "//components/CardMulticlass",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/CardMulticlass.vue")
  },
  {
    name: "index-components-CardMulticlass___ja-JP",
    path: "/ja-JP//components/CardMulticlass",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/CardMulticlass.vue")
  },
  {
    name: "index-components-CardMulticlass___de",
    path: "/de//components/CardMulticlass",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/CardMulticlass.vue")
  },
  {
    name: "index-components-CardMulticlass___es",
    path: "/es//components/CardMulticlass",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/CardMulticlass.vue")
  },
  {
    name: "index-components-ColorfulThreeFrames___en",
    path: "//components/ColorfulThreeFrames",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ColorfulThreeFrames.vue")
  },
  {
    name: "index-components-ColorfulThreeFrames___ja-JP",
    path: "/ja-JP//components/ColorfulThreeFrames",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ColorfulThreeFrames.vue")
  },
  {
    name: "index-components-ColorfulThreeFrames___de",
    path: "/de//components/ColorfulThreeFrames",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ColorfulThreeFrames.vue")
  },
  {
    name: "index-components-ColorfulThreeFrames___es",
    path: "/es//components/ColorfulThreeFrames",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ColorfulThreeFrames.vue")
  },
  {
    name: "index-components-ContactCard___en",
    path: "//components/ContactCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ContactCard.vue")
  },
  {
    name: "index-components-ContactCard___ja-JP",
    path: "/ja-JP//components/ContactCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ContactCard.vue")
  },
  {
    name: "index-components-ContactCard___de",
    path: "/de//components/ContactCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ContactCard.vue")
  },
  {
    name: "index-components-ContactCard___es",
    path: "/es//components/ContactCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ContactCard.vue")
  },
  {
    name: "index-components-CustomerCard___en",
    path: "//components/CustomerCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/CustomerCard.vue")
  },
  {
    name: "index-components-CustomerCard___ja-JP",
    path: "/ja-JP//components/CustomerCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/CustomerCard.vue")
  },
  {
    name: "index-components-CustomerCard___de",
    path: "/de//components/CustomerCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/CustomerCard.vue")
  },
  {
    name: "index-components-CustomerCard___es",
    path: "/es//components/CustomerCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/CustomerCard.vue")
  },
  {
    name: "index-components-EnterprisesComment___en",
    path: "//components/EnterprisesComment",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/EnterprisesComment.vue")
  },
  {
    name: "index-components-EnterprisesComment___ja-JP",
    path: "/ja-JP//components/EnterprisesComment",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/EnterprisesComment.vue")
  },
  {
    name: "index-components-EnterprisesComment___de",
    path: "/de//components/EnterprisesComment",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/EnterprisesComment.vue")
  },
  {
    name: "index-components-EnterprisesComment___es",
    path: "/es//components/EnterprisesComment",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/EnterprisesComment.vue")
  },
  {
    name: "index-components-FiveCard___en",
    path: "//components/FiveCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/FiveCard.vue")
  },
  {
    name: "index-components-FiveCard___ja-JP",
    path: "/ja-JP//components/FiveCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/FiveCard.vue")
  },
  {
    name: "index-components-FiveCard___de",
    path: "/de//components/FiveCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/FiveCard.vue")
  },
  {
    name: "index-components-FiveCard___es",
    path: "/es//components/FiveCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/FiveCard.vue")
  },
  {
    name: "index-components___en",
    path: "//components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/index.ts")
  },
  {
    name: "index-components___ja-JP",
    path: "/ja-JP//components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/index.ts")
  },
  {
    name: "index-components___de",
    path: "/de//components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/index.ts")
  },
  {
    name: "index-components___es",
    path: "/es//components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/index.ts")
  },
  {
    name: "index-components-IndexSectionFive___en",
    path: "//components/IndexSectionFive",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionFive.vue")
  },
  {
    name: "index-components-IndexSectionFive___ja-JP",
    path: "/ja-JP//components/IndexSectionFive",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionFive.vue")
  },
  {
    name: "index-components-IndexSectionFive___de",
    path: "/de//components/IndexSectionFive",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionFive.vue")
  },
  {
    name: "index-components-IndexSectionFive___es",
    path: "/es//components/IndexSectionFive",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionFive.vue")
  },
  {
    name: "index-components-IndexSectionOne___en",
    path: "//components/IndexSectionOne",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionOne.vue")
  },
  {
    name: "index-components-IndexSectionOne___ja-JP",
    path: "/ja-JP//components/IndexSectionOne",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionOne.vue")
  },
  {
    name: "index-components-IndexSectionOne___de",
    path: "/de//components/IndexSectionOne",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionOne.vue")
  },
  {
    name: "index-components-IndexSectionOne___es",
    path: "/es//components/IndexSectionOne",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionOne.vue")
  },
  {
    name: "index-components-IndexSectionOneV2___en",
    path: "//components/IndexSectionOneV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionOneV2.vue")
  },
  {
    name: "index-components-IndexSectionOneV2___ja-JP",
    path: "/ja-JP//components/IndexSectionOneV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionOneV2.vue")
  },
  {
    name: "index-components-IndexSectionOneV2___de",
    path: "/de//components/IndexSectionOneV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionOneV2.vue")
  },
  {
    name: "index-components-IndexSectionOneV2___es",
    path: "/es//components/IndexSectionOneV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionOneV2.vue")
  },
  {
    name: "index-components-IndexSectionSix___en",
    path: "//components/IndexSectionSix",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionSix.vue")
  },
  {
    name: "index-components-IndexSectionSix___ja-JP",
    path: "/ja-JP//components/IndexSectionSix",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionSix.vue")
  },
  {
    name: "index-components-IndexSectionSix___de",
    path: "/de//components/IndexSectionSix",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionSix.vue")
  },
  {
    name: "index-components-IndexSectionSix___es",
    path: "/es//components/IndexSectionSix",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionSix.vue")
  },
  {
    name: "index-components-IndexSectionThree___en",
    path: "//components/IndexSectionThree",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionThree.vue")
  },
  {
    name: "index-components-IndexSectionThree___ja-JP",
    path: "/ja-JP//components/IndexSectionThree",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionThree.vue")
  },
  {
    name: "index-components-IndexSectionThree___de",
    path: "/de//components/IndexSectionThree",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionThree.vue")
  },
  {
    name: "index-components-IndexSectionThree___es",
    path: "/es//components/IndexSectionThree",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionThree.vue")
  },
  {
    name: "index-components-IndexSectionTwo___en",
    path: "//components/IndexSectionTwo",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionTwo.vue")
  },
  {
    name: "index-components-IndexSectionTwo___ja-JP",
    path: "/ja-JP//components/IndexSectionTwo",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionTwo.vue")
  },
  {
    name: "index-components-IndexSectionTwo___de",
    path: "/de//components/IndexSectionTwo",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionTwo.vue")
  },
  {
    name: "index-components-IndexSectionTwo___es",
    path: "/es//components/IndexSectionTwo",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionTwo.vue")
  },
  {
    name: "index-components-IndexSectionTwoV2___en",
    path: "//components/IndexSectionTwoV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionTwoV2.vue")
  },
  {
    name: "index-components-IndexSectionTwoV2___ja-JP",
    path: "/ja-JP//components/IndexSectionTwoV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionTwoV2.vue")
  },
  {
    name: "index-components-IndexSectionTwoV2___de",
    path: "/de//components/IndexSectionTwoV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionTwoV2.vue")
  },
  {
    name: "index-components-IndexSectionTwoV2___es",
    path: "/es//components/IndexSectionTwoV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/IndexSectionTwoV2.vue")
  },
  {
    name: "index-components-PartContainer___en",
    path: "//components/PartContainer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/PartContainer.vue")
  },
  {
    name: "index-components-PartContainer___ja-JP",
    path: "/ja-JP//components/PartContainer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/PartContainer.vue")
  },
  {
    name: "index-components-PartContainer___de",
    path: "/de//components/PartContainer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/PartContainer.vue")
  },
  {
    name: "index-components-PartContainer___es",
    path: "/es//components/PartContainer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/PartContainer.vue")
  },
  {
    name: "index-components-ProductCard___en",
    path: "//components/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ProductCard.vue")
  },
  {
    name: "index-components-ProductCard___ja-JP",
    path: "/ja-JP//components/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ProductCard.vue")
  },
  {
    name: "index-components-ProductCard___de",
    path: "/de//components/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ProductCard.vue")
  },
  {
    name: "index-components-ProductCard___es",
    path: "/es//components/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ProductCard.vue")
  },
  {
    name: "index-components-ProductShowCard___en",
    path: "//components/ProductShowCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ProductShowCard.vue")
  },
  {
    name: "index-components-ProductShowCard___ja-JP",
    path: "/ja-JP//components/ProductShowCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ProductShowCard.vue")
  },
  {
    name: "index-components-ProductShowCard___de",
    path: "/de//components/ProductShowCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ProductShowCard.vue")
  },
  {
    name: "index-components-ProductShowCard___es",
    path: "/es//components/ProductShowCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/ProductShowCard.vue")
  },
  {
    name: "index-components-SenceCard___en",
    path: "//components/SenceCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/SenceCard.vue")
  },
  {
    name: "index-components-SenceCard___ja-JP",
    path: "/ja-JP//components/SenceCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/SenceCard.vue")
  },
  {
    name: "index-components-SenceCard___de",
    path: "/de//components/SenceCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/SenceCard.vue")
  },
  {
    name: "index-components-SenceCard___es",
    path: "/es//components/SenceCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/components/SenceCard.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/index.vue")
  },
  {
    name: "index___ja-JP",
    path: "/ja-JP",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/index/index.vue")
  },
  {
    name: "mirror-model___en",
    path: "/mirror/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/mirror/[model].vue")
  },
  {
    name: "mirror-model___ja-JP",
    path: "/ja-JP/mirror/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/mirror/[model].vue")
  },
  {
    name: "mirror-model___de",
    path: "/de/mirror/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/mirror/[model].vue")
  },
  {
    name: "mirror-model___es",
    path: "/es/mirror/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/mirror/[model].vue")
  },
  {
    name: "onboarding-model___en",
    path: "/onboarding/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/onboarding/[model].vue")
  },
  {
    name: "onboarding-model___ja-JP",
    path: "/ja-JP/onboarding/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/onboarding/[model].vue")
  },
  {
    name: "onboarding-model___de",
    path: "/de/onboarding/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/onboarding/[model].vue")
  },
  {
    name: "onboarding-model___es",
    path: "/es/onboarding/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/onboarding/[model].vue")
  },
  {
    name: "payment-result___en",
    path: "/payment-result",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/payment-result/index.vue")
  },
  {
    name: "payment-result___ja-JP",
    path: "/ja-JP/payment-result",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/payment-result/index.vue")
  },
  {
    name: "payment-result___de",
    path: "/de/payment-result",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/payment-result/index.vue")
  },
  {
    name: "payment-result___es",
    path: "/es/payment-result",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/payment-result/index.vue")
  },
  {
    name: "post-id___en",
    path: "/post/:id()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/[id].vue")
  },
  {
    name: "post-id___ja-JP",
    path: "/ja-JP/post/:id()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/[id].vue")
  },
  {
    name: "post-id___de",
    path: "/de/post/:id()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/[id].vue")
  },
  {
    name: "post-id___es",
    path: "/es/post/:id()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/[id].vue")
  },
  {
    name: "post-components-render___en",
    path: "/post/components/render",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/components/render.vue")
  },
  {
    name: "post-components-render___ja-JP",
    path: "/ja-JP/post/components/render",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/components/render.vue")
  },
  {
    name: "post-components-render___de",
    path: "/de/post/components/render",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/components/render.vue")
  },
  {
    name: "post-components-render___es",
    path: "/es/post/components/render",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/components/render.vue")
  },
  {
    name: "post-components-Toc___en",
    path: "/post/components/Toc",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/components/Toc.vue")
  },
  {
    name: "post-components-Toc___ja-JP",
    path: "/ja-JP/post/components/Toc",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/components/Toc.vue")
  },
  {
    name: "post-components-Toc___de",
    path: "/de/post/components/Toc",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/components/Toc.vue")
  },
  {
    name: "post-components-Toc___es",
    path: "/es/post/components/Toc",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/post/components/Toc.vue")
  },
  {
    name: "pricing___en",
    path: "/pricing",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/pricing/index.vue")
  },
  {
    name: "pricing___ja-JP",
    path: "/ja-JP/pricing",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/pricing/index.vue")
  },
  {
    name: "pricing___de",
    path: "/de/pricing",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/pricing/index.vue")
  },
  {
    name: "pricing___es",
    path: "/es/pricing",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/pricing/index.vue")
  },
  {
    name: "product-model___en",
    path: "/product/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/[model].vue")
  },
  {
    name: "product-model___ja-JP",
    path: "/ja-JP/product/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/[model].vue")
  },
  {
    name: "product-model___de",
    path: "/de/product/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/[model].vue")
  },
  {
    name: "product-model___es",
    path: "/es/product/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/[model].vue")
  },
  {
    name: "product-components-DownloadPDF___en",
    path: "/product/components/DownloadPDF",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/DownloadPDF.vue")
  },
  {
    name: "product-components-DownloadPDF___ja-JP",
    path: "/ja-JP/product/components/DownloadPDF",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/DownloadPDF.vue")
  },
  {
    name: "product-components-DownloadPDF___de",
    path: "/de/product/components/DownloadPDF",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/DownloadPDF.vue")
  },
  {
    name: "product-components-DownloadPDF___es",
    path: "/es/product/components/DownloadPDF",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/DownloadPDF.vue")
  },
  {
    name: "product-components-FeatureCard___en",
    path: "/product/components/FeatureCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/FeatureCard.vue")
  },
  {
    name: "product-components-FeatureCard___ja-JP",
    path: "/ja-JP/product/components/FeatureCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/FeatureCard.vue")
  },
  {
    name: "product-components-FeatureCard___de",
    path: "/de/product/components/FeatureCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/FeatureCard.vue")
  },
  {
    name: "product-components-FeatureCard___es",
    path: "/es/product/components/FeatureCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/FeatureCard.vue")
  },
  {
    name: "product-components___en",
    path: "/product/components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/index.ts")
  },
  {
    name: "product-components___ja-JP",
    path: "/ja-JP/product/components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/index.ts")
  },
  {
    name: "product-components___de",
    path: "/de/product/components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/index.ts")
  },
  {
    name: "product-components___es",
    path: "/es/product/components",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/index.ts")
  },
  {
    name: "product-components-ProductAdvantage___en",
    path: "/product/components/ProductAdvantage",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductAdvantage.vue")
  },
  {
    name: "product-components-ProductAdvantage___ja-JP",
    path: "/ja-JP/product/components/ProductAdvantage",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductAdvantage.vue")
  },
  {
    name: "product-components-ProductAdvantage___de",
    path: "/de/product/components/ProductAdvantage",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductAdvantage.vue")
  },
  {
    name: "product-components-ProductAdvantage___es",
    path: "/es/product/components/ProductAdvantage",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductAdvantage.vue")
  },
  {
    name: "product-components-ProductBanner___en",
    path: "/product/components/ProductBanner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductBanner.vue")
  },
  {
    name: "product-components-ProductBanner___ja-JP",
    path: "/ja-JP/product/components/ProductBanner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductBanner.vue")
  },
  {
    name: "product-components-ProductBanner___de",
    path: "/de/product/components/ProductBanner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductBanner.vue")
  },
  {
    name: "product-components-ProductBanner___es",
    path: "/es/product/components/ProductBanner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductBanner.vue")
  },
  {
    name: "product-components-ProductCareProgram___en",
    path: "/product/components/ProductCareProgram",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductCareProgram.vue")
  },
  {
    name: "product-components-ProductCareProgram___ja-JP",
    path: "/ja-JP/product/components/ProductCareProgram",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductCareProgram.vue")
  },
  {
    name: "product-components-ProductCareProgram___de",
    path: "/de/product/components/ProductCareProgram",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductCareProgram.vue")
  },
  {
    name: "product-components-ProductCareProgram___es",
    path: "/es/product/components/ProductCareProgram",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductCareProgram.vue")
  },
  {
    name: "product-components-ProductPromise___en",
    path: "/product/components/ProductPromise",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductPromise.vue")
  },
  {
    name: "product-components-ProductPromise___ja-JP",
    path: "/ja-JP/product/components/ProductPromise",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductPromise.vue")
  },
  {
    name: "product-components-ProductPromise___de",
    path: "/de/product/components/ProductPromise",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductPromise.vue")
  },
  {
    name: "product-components-ProductPromise___es",
    path: "/es/product/components/ProductPromise",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/ProductPromise.vue")
  },
  {
    name: "product-components-TechInfoTab___en",
    path: "/product/components/TechInfoTab",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/TechInfoTab.vue")
  },
  {
    name: "product-components-TechInfoTab___ja-JP",
    path: "/ja-JP/product/components/TechInfoTab",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/TechInfoTab.vue")
  },
  {
    name: "product-components-TechInfoTab___de",
    path: "/de/product/components/TechInfoTab",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/TechInfoTab.vue")
  },
  {
    name: "product-components-TechInfoTab___es",
    path: "/es/product/components/TechInfoTab",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/TechInfoTab.vue")
  },
  {
    name: "product-components-VideoCard___en",
    path: "/product/components/VideoCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/VideoCard.vue")
  },
  {
    name: "product-components-VideoCard___ja-JP",
    path: "/ja-JP/product/components/VideoCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/VideoCard.vue")
  },
  {
    name: "product-components-VideoCard___de",
    path: "/de/product/components/VideoCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/VideoCard.vue")
  },
  {
    name: "product-components-VideoCard___es",
    path: "/es/product/components/VideoCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/components/VideoCard.vue")
  },
  {
    name: "product-componentsV2-BookDemo___en",
    path: "/product/componentsV2/BookDemo",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/BookDemo.vue")
  },
  {
    name: "product-componentsV2-BookDemo___ja-JP",
    path: "/ja-JP/product/componentsV2/BookDemo",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/BookDemo.vue")
  },
  {
    name: "product-componentsV2-BookDemo___de",
    path: "/de/product/componentsV2/BookDemo",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/BookDemo.vue")
  },
  {
    name: "product-componentsV2-BookDemo___es",
    path: "/es/product/componentsV2/BookDemo",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/BookDemo.vue")
  },
  {
    name: "product-componentsV2-CardTechSpecV2___en",
    path: "/product/componentsV2/CardTechSpecV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/CardTechSpecV2.vue")
  },
  {
    name: "product-componentsV2-CardTechSpecV2___ja-JP",
    path: "/ja-JP/product/componentsV2/CardTechSpecV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/CardTechSpecV2.vue")
  },
  {
    name: "product-componentsV2-CardTechSpecV2___de",
    path: "/de/product/componentsV2/CardTechSpecV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/CardTechSpecV2.vue")
  },
  {
    name: "product-componentsV2-CardTechSpecV2___es",
    path: "/es/product/componentsV2/CardTechSpecV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/CardTechSpecV2.vue")
  },
  {
    name: "product-componentsV2-ContactSale___en",
    path: "/product/componentsV2/ContactSale",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ContactSale.vue")
  },
  {
    name: "product-componentsV2-ContactSale___ja-JP",
    path: "/ja-JP/product/componentsV2/ContactSale",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ContactSale.vue")
  },
  {
    name: "product-componentsV2-ContactSale___de",
    path: "/de/product/componentsV2/ContactSale",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ContactSale.vue")
  },
  {
    name: "product-componentsV2-ContactSale___es",
    path: "/es/product/componentsV2/ContactSale",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ContactSale.vue")
  },
  {
    name: "product-componentsV2-customer-review-CustomerReview___en",
    path: "/product/componentsV2/customer-review/CustomerReview",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/customer-review/CustomerReview.vue")
  },
  {
    name: "product-componentsV2-customer-review-CustomerReview___ja-JP",
    path: "/ja-JP/product/componentsV2/customer-review/CustomerReview",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/customer-review/CustomerReview.vue")
  },
  {
    name: "product-componentsV2-customer-review-CustomerReview___de",
    path: "/de/product/componentsV2/customer-review/CustomerReview",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/customer-review/CustomerReview.vue")
  },
  {
    name: "product-componentsV2-customer-review-CustomerReview___es",
    path: "/es/product/componentsV2/customer-review/CustomerReview",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/customer-review/CustomerReview.vue")
  },
  {
    name: "product-componentsV2-customer-review-ReviewCard___en",
    path: "/product/componentsV2/customer-review/ReviewCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/customer-review/ReviewCard.vue")
  },
  {
    name: "product-componentsV2-customer-review-ReviewCard___ja-JP",
    path: "/ja-JP/product/componentsV2/customer-review/ReviewCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/customer-review/ReviewCard.vue")
  },
  {
    name: "product-componentsV2-customer-review-ReviewCard___de",
    path: "/de/product/componentsV2/customer-review/ReviewCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/customer-review/ReviewCard.vue")
  },
  {
    name: "product-componentsV2-customer-review-ReviewCard___es",
    path: "/es/product/componentsV2/customer-review/ReviewCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/customer-review/ReviewCard.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-Coupon___en",
    path: "/product/componentsV2/ergo-product-view/Coupon",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/Coupon.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-Coupon___ja-JP",
    path: "/ja-JP/product/componentsV2/ergo-product-view/Coupon",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/Coupon.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-Coupon___de",
    path: "/de/product/componentsV2/ergo-product-view/Coupon",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/Coupon.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-Coupon___es",
    path: "/es/product/componentsV2/ergo-product-view/Coupon",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/Coupon.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-FeatureList___en",
    path: "/product/componentsV2/ergo-product-view/FeatureList",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/FeatureList.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-FeatureList___ja-JP",
    path: "/ja-JP/product/componentsV2/ergo-product-view/FeatureList",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/FeatureList.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-FeatureList___de",
    path: "/de/product/componentsV2/ergo-product-view/FeatureList",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/FeatureList.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-FeatureList___es",
    path: "/es/product/componentsV2/ergo-product-view/FeatureList",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/FeatureList.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-ProductCard___en",
    path: "/product/componentsV2/ergo-product-view/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/ProductCard.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-ProductCard___ja-JP",
    path: "/ja-JP/product/componentsV2/ergo-product-view/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/ProductCard.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-ProductCard___de",
    path: "/de/product/componentsV2/ergo-product-view/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/ProductCard.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-ProductCard___es",
    path: "/es/product/componentsV2/ergo-product-view/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/ProductCard.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-ProductViewV2___en",
    path: "/product/componentsV2/ergo-product-view/ProductViewV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/ProductViewV2.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-ProductViewV2___ja-JP",
    path: "/ja-JP/product/componentsV2/ergo-product-view/ProductViewV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/ProductViewV2.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-ProductViewV2___de",
    path: "/de/product/componentsV2/ergo-product-view/ProductViewV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/ProductViewV2.vue")
  },
  {
    name: "product-componentsV2-ergo-product-view-ProductViewV2___es",
    path: "/es/product/componentsV2/ergo-product-view/ProductViewV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ergo-product-view/ProductViewV2.vue")
  },
  {
    name: "product-componentsV2-feature-detail-FeatureDetail___en",
    path: "/product/componentsV2/feature-detail/FeatureDetail",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/FeatureDetail.vue")
  },
  {
    name: "product-componentsV2-feature-detail-FeatureDetail___ja-JP",
    path: "/ja-JP/product/componentsV2/feature-detail/FeatureDetail",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/FeatureDetail.vue")
  },
  {
    name: "product-componentsV2-feature-detail-FeatureDetail___de",
    path: "/de/product/componentsV2/feature-detail/FeatureDetail",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/FeatureDetail.vue")
  },
  {
    name: "product-componentsV2-feature-detail-FeatureDetail___es",
    path: "/es/product/componentsV2/feature-detail/FeatureDetail",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/FeatureDetail.vue")
  },
  {
    name: "product-componentsV2-feature-detail-IconCard___en",
    path: "/product/componentsV2/feature-detail/IconCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/IconCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-IconCard___ja-JP",
    path: "/ja-JP/product/componentsV2/feature-detail/IconCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/IconCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-IconCard___de",
    path: "/de/product/componentsV2/feature-detail/IconCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/IconCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-IconCard___es",
    path: "/es/product/componentsV2/feature-detail/IconCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/IconCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-MessageCard___en",
    path: "/product/componentsV2/feature-detail/MessageCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/MessageCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-MessageCard___ja-JP",
    path: "/ja-JP/product/componentsV2/feature-detail/MessageCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/MessageCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-MessageCard___de",
    path: "/de/product/componentsV2/feature-detail/MessageCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/MessageCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-MessageCard___es",
    path: "/es/product/componentsV2/feature-detail/MessageCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/MessageCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-PicTextCard___en",
    path: "/product/componentsV2/feature-detail/PicTextCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/PicTextCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-PicTextCard___ja-JP",
    path: "/ja-JP/product/componentsV2/feature-detail/PicTextCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/PicTextCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-PicTextCard___de",
    path: "/de/product/componentsV2/feature-detail/PicTextCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/PicTextCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-PicTextCard___es",
    path: "/es/product/componentsV2/feature-detail/PicTextCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/PicTextCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-SplideCard___en",
    path: "/product/componentsV2/feature-detail/SplideCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/SplideCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-SplideCard___ja-JP",
    path: "/ja-JP/product/componentsV2/feature-detail/SplideCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/SplideCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-SplideCard___de",
    path: "/de/product/componentsV2/feature-detail/SplideCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/SplideCard.vue")
  },
  {
    name: "product-componentsV2-feature-detail-SplideCard___es",
    path: "/es/product/componentsV2/feature-detail/SplideCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/feature-detail/SplideCard.vue")
  },
  {
    name: "product-componentsV2-GuideSplide___en",
    path: "/product/componentsV2/GuideSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/GuideSplide/index.vue")
  },
  {
    name: "product-componentsV2-GuideSplide___ja-JP",
    path: "/ja-JP/product/componentsV2/GuideSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/GuideSplide/index.vue")
  },
  {
    name: "product-componentsV2-GuideSplide___de",
    path: "/de/product/componentsV2/GuideSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/GuideSplide/index.vue")
  },
  {
    name: "product-componentsV2-GuideSplide___es",
    path: "/es/product/componentsV2/GuideSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/GuideSplide/index.vue")
  },
  {
    name: "product-componentsV2-InfoTabCard___en",
    path: "/product/componentsV2/InfoTabCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/InfoTabCard.vue")
  },
  {
    name: "product-componentsV2-InfoTabCard___ja-JP",
    path: "/ja-JP/product/componentsV2/InfoTabCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/InfoTabCard.vue")
  },
  {
    name: "product-componentsV2-InfoTabCard___de",
    path: "/de/product/componentsV2/InfoTabCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/InfoTabCard.vue")
  },
  {
    name: "product-componentsV2-InfoTabCard___es",
    path: "/es/product/componentsV2/InfoTabCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/InfoTabCard.vue")
  },
  {
    name: "product-componentsV2-InfoTabSplide___en",
    path: "/product/componentsV2/InfoTabSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/InfoTabSplide/index.vue")
  },
  {
    name: "product-componentsV2-InfoTabSplide___ja-JP",
    path: "/ja-JP/product/componentsV2/InfoTabSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/InfoTabSplide/index.vue")
  },
  {
    name: "product-componentsV2-InfoTabSplide___de",
    path: "/de/product/componentsV2/InfoTabSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/InfoTabSplide/index.vue")
  },
  {
    name: "product-componentsV2-InfoTabSplide___es",
    path: "/es/product/componentsV2/InfoTabSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/InfoTabSplide/index.vue")
  },
  {
    name: "product-componentsV2-ListSplide___en",
    path: "/product/componentsV2/ListSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ListSplide.vue")
  },
  {
    name: "product-componentsV2-ListSplide___ja-JP",
    path: "/ja-JP/product/componentsV2/ListSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ListSplide.vue")
  },
  {
    name: "product-componentsV2-ListSplide___de",
    path: "/de/product/componentsV2/ListSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ListSplide.vue")
  },
  {
    name: "product-componentsV2-ListSplide___es",
    path: "/es/product/componentsV2/ListSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/ListSplide.vue")
  },
  {
    name: "product-componentsV2-product-view-Coupon___en",
    path: "/product/componentsV2/product-view/Coupon",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/Coupon.vue")
  },
  {
    name: "product-componentsV2-product-view-Coupon___ja-JP",
    path: "/ja-JP/product/componentsV2/product-view/Coupon",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/Coupon.vue")
  },
  {
    name: "product-componentsV2-product-view-Coupon___de",
    path: "/de/product/componentsV2/product-view/Coupon",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/Coupon.vue")
  },
  {
    name: "product-componentsV2-product-view-Coupon___es",
    path: "/es/product/componentsV2/product-view/Coupon",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/Coupon.vue")
  },
  {
    name: "product-componentsV2-product-view-FeatureList___en",
    path: "/product/componentsV2/product-view/FeatureList",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/FeatureList.vue")
  },
  {
    name: "product-componentsV2-product-view-FeatureList___ja-JP",
    path: "/ja-JP/product/componentsV2/product-view/FeatureList",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/FeatureList.vue")
  },
  {
    name: "product-componentsV2-product-view-FeatureList___de",
    path: "/de/product/componentsV2/product-view/FeatureList",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/FeatureList.vue")
  },
  {
    name: "product-componentsV2-product-view-FeatureList___es",
    path: "/es/product/componentsV2/product-view/FeatureList",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/FeatureList.vue")
  },
  {
    name: "product-componentsV2-product-view-ProductCard___en",
    path: "/product/componentsV2/product-view/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/ProductCard.vue")
  },
  {
    name: "product-componentsV2-product-view-ProductCard___ja-JP",
    path: "/ja-JP/product/componentsV2/product-view/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/ProductCard.vue")
  },
  {
    name: "product-componentsV2-product-view-ProductCard___de",
    path: "/de/product/componentsV2/product-view/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/ProductCard.vue")
  },
  {
    name: "product-componentsV2-product-view-ProductCard___es",
    path: "/es/product/componentsV2/product-view/ProductCard",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/ProductCard.vue")
  },
  {
    name: "product-componentsV2-product-view-ProductViewV2___en",
    path: "/product/componentsV2/product-view/ProductViewV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/ProductViewV2.vue")
  },
  {
    name: "product-componentsV2-product-view-ProductViewV2___ja-JP",
    path: "/ja-JP/product/componentsV2/product-view/ProductViewV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/ProductViewV2.vue")
  },
  {
    name: "product-componentsV2-product-view-ProductViewV2___de",
    path: "/de/product/componentsV2/product-view/ProductViewV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/ProductViewV2.vue")
  },
  {
    name: "product-componentsV2-product-view-ProductViewV2___es",
    path: "/es/product/componentsV2/product-view/ProductViewV2",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/product-view/ProductViewV2.vue")
  },
  {
    name: "product-componentsV2-SlideSwiper___en",
    path: "/product/componentsV2/SlideSwiper",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/SlideSwiper/index.vue")
  },
  {
    name: "product-componentsV2-SlideSwiper___ja-JP",
    path: "/ja-JP/product/componentsV2/SlideSwiper",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/SlideSwiper/index.vue")
  },
  {
    name: "product-componentsV2-SlideSwiper___de",
    path: "/de/product/componentsV2/SlideSwiper",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/SlideSwiper/index.vue")
  },
  {
    name: "product-componentsV2-SlideSwiper___es",
    path: "/es/product/componentsV2/SlideSwiper",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/SlideSwiper/index.vue")
  },
  {
    name: "product-componentsV2-TextPic___en",
    path: "/product/componentsV2/TextPic",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/TextPic.vue")
  },
  {
    name: "product-componentsV2-TextPic___ja-JP",
    path: "/ja-JP/product/componentsV2/TextPic",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/TextPic.vue")
  },
  {
    name: "product-componentsV2-TextPic___de",
    path: "/de/product/componentsV2/TextPic",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/TextPic.vue")
  },
  {
    name: "product-componentsV2-TextPic___es",
    path: "/es/product/componentsV2/TextPic",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/TextPic.vue")
  },
  {
    name: "product-componentsV2-VideoIntro___en",
    path: "/product/componentsV2/VideoIntro",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/VideoIntro.vue")
  },
  {
    name: "product-componentsV2-VideoIntro___ja-JP",
    path: "/ja-JP/product/componentsV2/VideoIntro",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/VideoIntro.vue")
  },
  {
    name: "product-componentsV2-VideoIntro___de",
    path: "/de/product/componentsV2/VideoIntro",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/VideoIntro.vue")
  },
  {
    name: "product-componentsV2-VideoIntro___es",
    path: "/es/product/componentsV2/VideoIntro",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/componentsV2/VideoIntro.vue")
  },
  {
    name: "product-useChatBot___en",
    path: "/product/useChatBot",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/useChatBot.ts")
  },
  {
    name: "product-useChatBot___ja-JP",
    path: "/ja-JP/product/useChatBot",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/useChatBot.ts")
  },
  {
    name: "product-useChatBot___de",
    path: "/de/product/useChatBot",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/useChatBot.ts")
  },
  {
    name: "product-useChatBot___es",
    path: "/es/product/useChatBot",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/product/useChatBot.ts")
  },
  {
    name: "resources-academy-components-TutorialNav___en",
    path: "/resources/academy/components/TutorialNav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/academy/components/TutorialNav.vue")
  },
  {
    name: "resources-academy-components-TutorialNav___ja-JP",
    path: "/ja-JP/resources/academy/components/TutorialNav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/academy/components/TutorialNav.vue")
  },
  {
    name: "resources-academy-components-TutorialNav___de",
    path: "/de/resources/academy/components/TutorialNav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/academy/components/TutorialNav.vue")
  },
  {
    name: "resources-academy-components-TutorialNav___es",
    path: "/es/resources/academy/components/TutorialNav",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/academy/components/TutorialNav.vue")
  },
  {
    name: "resources-academy___en",
    path: "/resources/academy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/academy/index.vue")
  },
  {
    name: "resources-academy___ja-JP",
    path: "/ja-JP/resources/academy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/academy/index.vue")
  },
  {
    name: "resources-academy___de",
    path: "/de/resources/academy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/academy/index.vue")
  },
  {
    name: "resources-academy___es",
    path: "/es/resources/academy",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/academy/index.vue")
  },
  {
    name: "resources-buyer-faq___en",
    path: "/resources/buyer-faq",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/buyer-faq/index.vue")
  },
  {
    name: "resources-buyer-faq___ja-JP",
    path: "/ja-JP/resources/buyer-faq",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/buyer-faq/index.vue")
  },
  {
    name: "resources-buyer-faq___de",
    path: "/de/resources/buyer-faq",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/buyer-faq/index.vue")
  },
  {
    name: "resources-buyer-faq___es",
    path: "/es/resources/buyer-faq",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/buyer-faq/index.vue")
  },
  {
    name: "resources-download-center___en",
    path: "/resources/download-center",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/download-center/index.vue")
  },
  {
    name: "resources-download-center___ja-JP",
    path: "/ja-JP/resources/download-center",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/download-center/index.vue")
  },
  {
    name: "resources-download-center___de",
    path: "/de/resources/download-center",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/download-center/index.vue")
  },
  {
    name: "resources-download-center___es",
    path: "/es/resources/download-center",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/download-center/index.vue")
  },
  {
    name: "resources-help-center___en",
    path: "/resources/help-center",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/help-center/index.vue")
  },
  {
    name: "resources-help-center___ja-JP",
    path: "/ja-JP/resources/help-center",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/help-center/index.vue")
  },
  {
    name: "resources-help-center___de",
    path: "/de/resources/help-center",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/help-center/index.vue")
  },
  {
    name: "resources-help-center___es",
    path: "/es/resources/help-center",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/help-center/index.vue")
  },
  {
    name: "resources-onboarding-component-Canvas___en",
    path: "/resources/onboarding/component/Canvas",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Canvas.vue")
  },
  {
    name: "resources-onboarding-component-Canvas___ja-JP",
    path: "/ja-JP/resources/onboarding/component/Canvas",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Canvas.vue")
  },
  {
    name: "resources-onboarding-component-Canvas___de",
    path: "/de/resources/onboarding/component/Canvas",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Canvas.vue")
  },
  {
    name: "resources-onboarding-component-Canvas___es",
    path: "/es/resources/onboarding/component/Canvas",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Canvas.vue")
  },
  {
    name: "resources-onboarding-component-InstallGuide___en",
    path: "/resources/onboarding/component/InstallGuide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/InstallGuide.vue")
  },
  {
    name: "resources-onboarding-component-InstallGuide___ja-JP",
    path: "/ja-JP/resources/onboarding/component/InstallGuide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/InstallGuide.vue")
  },
  {
    name: "resources-onboarding-component-InstallGuide___de",
    path: "/de/resources/onboarding/component/InstallGuide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/InstallGuide.vue")
  },
  {
    name: "resources-onboarding-component-InstallGuide___es",
    path: "/es/resources/onboarding/component/InstallGuide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/InstallGuide.vue")
  },
  {
    name: "resources-onboarding-component-Resources___en",
    path: "/resources/onboarding/component/Resources",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Resources.vue")
  },
  {
    name: "resources-onboarding-component-Resources___ja-JP",
    path: "/ja-JP/resources/onboarding/component/Resources",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Resources.vue")
  },
  {
    name: "resources-onboarding-component-Resources___de",
    path: "/de/resources/onboarding/component/Resources",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Resources.vue")
  },
  {
    name: "resources-onboarding-component-Resources___es",
    path: "/es/resources/onboarding/component/Resources",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Resources.vue")
  },
  {
    name: "resources-onboarding-component-Steps___en",
    path: "/resources/onboarding/component/Steps",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Steps.vue")
  },
  {
    name: "resources-onboarding-component-Steps___ja-JP",
    path: "/ja-JP/resources/onboarding/component/Steps",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Steps.vue")
  },
  {
    name: "resources-onboarding-component-Steps___de",
    path: "/de/resources/onboarding/component/Steps",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Steps.vue")
  },
  {
    name: "resources-onboarding-component-Steps___es",
    path: "/es/resources/onboarding/component/Steps",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/component/Steps.vue")
  },
  {
    name: "resources-onboarding___en",
    path: "/resources/onboarding",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/index.vue")
  },
  {
    name: "resources-onboarding___ja-JP",
    path: "/ja-JP/resources/onboarding",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/index.vue")
  },
  {
    name: "resources-onboarding___de",
    path: "/de/resources/onboarding",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/index.vue")
  },
  {
    name: "resources-onboarding___es",
    path: "/es/resources/onboarding",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/resources/onboarding/index.vue")
  },
  {
    name: "saas-components-Banner___en",
    path: "/saas/components/Banner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/Banner.vue")
  },
  {
    name: "saas-components-Banner___ja-JP",
    path: "/ja-JP/saas/components/Banner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/Banner.vue")
  },
  {
    name: "saas-components-Banner___de",
    path: "/de/saas/components/Banner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/Banner.vue")
  },
  {
    name: "saas-components-Banner___es",
    path: "/es/saas/components/Banner",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/Banner.vue")
  },
  {
    name: "saas-components-Bottom___en",
    path: "/saas/components/Bottom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/Bottom.vue")
  },
  {
    name: "saas-components-Bottom___ja-JP",
    path: "/ja-JP/saas/components/Bottom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/Bottom.vue")
  },
  {
    name: "saas-components-Bottom___de",
    path: "/de/saas/components/Bottom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/Bottom.vue")
  },
  {
    name: "saas-components-Bottom___es",
    path: "/es/saas/components/Bottom",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/Bottom.vue")
  },
  {
    name: "saas-components-CardAdvantage___en",
    path: "/saas/components/CardAdvantage",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/CardAdvantage.vue")
  },
  {
    name: "saas-components-CardAdvantage___ja-JP",
    path: "/ja-JP/saas/components/CardAdvantage",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/CardAdvantage.vue")
  },
  {
    name: "saas-components-CardAdvantage___de",
    path: "/de/saas/components/CardAdvantage",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/CardAdvantage.vue")
  },
  {
    name: "saas-components-CardAdvantage___es",
    path: "/es/saas/components/CardAdvantage",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/CardAdvantage.vue")
  },
  {
    name: "saas-components-TransformContainer___en",
    path: "/saas/components/TransformContainer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/TransformContainer.vue")
  },
  {
    name: "saas-components-TransformContainer___ja-JP",
    path: "/ja-JP/saas/components/TransformContainer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/TransformContainer.vue")
  },
  {
    name: "saas-components-TransformContainer___de",
    path: "/de/saas/components/TransformContainer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/TransformContainer.vue")
  },
  {
    name: "saas-components-TransformContainer___es",
    path: "/es/saas/components/TransformContainer",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/components/TransformContainer.vue")
  },
  {
    name: "saas___en",
    path: "/saas",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/index.vue")
  },
  {
    name: "saas___ja-JP",
    path: "/ja-JP/saas",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/index.vue")
  },
  {
    name: "saas___de",
    path: "/de/saas",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/index.vue")
  },
  {
    name: "saas___es",
    path: "/es/saas",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/saas/index.vue")
  },
  {
    name: "shop-model___en",
    path: "/shop/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/shop/[model].vue")
  },
  {
    name: "shop-model___ja-JP",
    path: "/ja-JP/shop/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/shop/[model].vue")
  },
  {
    name: "shop-model___de",
    path: "/de/shop/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/shop/[model].vue")
  },
  {
    name: "shop-model___es",
    path: "/es/shop/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/shop/[model].vue")
  },
  {
    name: "shop-components-ShopSplide___en",
    path: "/shop/components/ShopSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/shop/components/ShopSplide.vue")
  },
  {
    name: "shop-components-ShopSplide___ja-JP",
    path: "/ja-JP/shop/components/ShopSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/shop/components/ShopSplide.vue")
  },
  {
    name: "shop-components-ShopSplide___de",
    path: "/de/shop/components/ShopSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/shop/components/ShopSplide.vue")
  },
  {
    name: "shop-components-ShopSplide___es",
    path: "/es/shop/components/ShopSplide",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/shop/components/ShopSplide.vue")
  },
  {
    name: "solutions-model___en",
    path: "/solutions/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/solutions/[model].vue")
  },
  {
    name: "solutions-model___ja-JP",
    path: "/ja-JP/solutions/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/solutions/[model].vue")
  },
  {
    name: "solutions-model___de",
    path: "/de/solutions/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/solutions/[model].vue")
  },
  {
    name: "solutions-model___es",
    path: "/es/solutions/:model()",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/solutions/[model].vue")
  },
  {
    name: "viewer-components-BlogAriticl___en",
    path: "/viewer/components/BlogAriticl",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/components/BlogAriticl.vue")
  },
  {
    name: "viewer-components-BlogAriticl___ja-JP",
    path: "/ja-JP/viewer/components/BlogAriticl",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/components/BlogAriticl.vue")
  },
  {
    name: "viewer-components-BlogAriticl___de",
    path: "/de/viewer/components/BlogAriticl",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/components/BlogAriticl.vue")
  },
  {
    name: "viewer-components-BlogAriticl___es",
    path: "/es/viewer/components/BlogAriticl",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/components/BlogAriticl.vue")
  },
  {
    name: "blogViewer___en",
    path: "/blog/:id",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/[id].vue")
  },
  {
    name: "blogViewer___ja-JP",
    path: "/ja-JP/blog/:id",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/[id].vue")
  },
  {
    name: "blogViewer___de",
    path: "/de/blog/:id",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/[id].vue")
  },
  {
    name: "blogViewer___es",
    path: "/es/blog/:id",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/[id].vue")
  },
  {
    name: "HelpCenterViewer___en",
    path: "/resources/help-center/:id",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/[id].vue")
  },
  {
    name: "HelpCenterViewer___ja-JP",
    path: "/ja-JP/resources/help-center/:id",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/[id].vue")
  },
  {
    name: "HelpCenterViewer___de",
    path: "/de/resources/help-center/:id",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/[id].vue")
  },
  {
    name: "HelpCenterViewer___es",
    path: "/es/resources/help-center/:id",
    component: () => import("/var/jenkins_home/workspace/nearhub-websites-bundle/nearhub-website/pages/viewer/[id].vue")
  },
  {
    name: component_45stubNptZxHGK6cMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubNptZxHGK6c
  }
]