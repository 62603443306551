export default defineNuxtRouteMiddleware(async (e) => {
    const host = useRequestURL().host;

    if (host && (host.includes("ergo") || host.includes("localhost"))) {
        updateSiteConfig({
            name: "NearHub Ergo",
            indexable: false,
            url: "https://ergo.nearhub.us/",
        });
    }
});
