import { defineNuxtPlugin, useRequestURL, useRuntimeConfig } from "nuxt/app";

export default defineNuxtPlugin(() => {
    if (process.server) {
        const config = useRuntimeConfig();
        const requestURL = useRequestURL();
        // 动态设置 Strapi URL
        if (requestURL.host.includes("ergo")) {
            config.strapi.url = "https://cms-ergo.nearhub.us";
        } else {
            config.strapi.url = "https://cms.nearhub.us";
        }
        config.public.strapi.url = config.strapi.url;
    }
    // if (process.client) {
    //     console.log("config.public.strapi", config.public.strapi);
    //     // config.public.strapi.url = config.strapi.url;
    // }

    // console.log(config.public.strapi.url);
});
