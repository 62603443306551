/**
 * @see https://matteo-gabriele.gitbook.io/vue-gtag/v/next/basic-migration-guide
 */
// import VueGtag from "vue-gtag-next";

export default defineNuxtPlugin((nuxtApp) => {
    // const {
    //     public: { gtagId },
    // } = useRuntimeConfig();
    // nuxtApp.vueApp.use(VueGtag, {
    //     property: {
    //         id: gtagId,
    //     },
    // });
});
