const getCheckCartTrackData = () => {
    return {
        contents: window.payment.currentDrawer.totalList.reduce((pre: any, item: any) => {
            const current = pre.find((innerItem: any) => innerItem.id === item.spu);
            if (current) {
                current.quantity += item.totalNum;
                return pre;
            }
            return [
                ...pre,
                {
                    id: item.spu,
                    quantity: item.totalNum,
                },
            ];
        }, []),
    };
};

const trackFnMap = {
    CheckCart: getCheckCartTrackData,
} as any;

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive("fbq-tracker", {
        mounted(el, { value, arg, modifiers: { custom } }) {
            el.hander = () => {
                window?.fbq(custom ? "trackCustom" : "track", arg, value ? value : trackFnMap[arg as any]?.());
            };
            el.addEventListener("click", el.hander);
        },
        beforeUnmount(el) {
            el.removeEventListener("click", el.hander);
        },
    });
});
