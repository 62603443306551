export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive("gtag-tracker", {
        mounted(el, { value, arg }) {
            el.hander = () => {
                if ((window as any).washeng_tracking_v2) {
                    (window as any).washeng_tracking_v2.default("custom_report", {
                        $type: arg,
                        ...value,
                    });
                }
            };
            el.addEventListener("click", el.hander);
        },
        beforeUnmount(el) {
            el.removeEventListener("click", el.hander);
        },
    });
});
