// 服务端渲染使用， 判断当前品牌
export default function useHost() {
    // const app = useNuxtApp();
    // const host = process.server ? (app.ssrContext?.event.node.req.headers.host as string) : window.location.host;
    // const host = useRequestURL().host;

    // const brand = host.includes("ergo") ? "ergo" : "nearhub";
    // const brand = "ergo";

    return {
        host: "www.nearhub.us",
        brand: "nearhub",
    };
}
