import type { H3Error } from "h3";
import axios from "axios";
import { getMemTotal } from "~/utils/getMemTotal.server";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
        console.log("🚀 -> defineNuxtPlugin -> errorHandler:");
        // console.log("🚀 -> defineNuxtPlugin -> error:", error,instance,info);
        // handle error, e.g. report to a service
    };

    nuxtApp.hook("vue:error", (error: H3Error) => {
        console.log("🚀 -> nuxtApp:hook -> error:");
    });

    // Also possible
    nuxtApp.hook("app:error", (error: H3Error) => {
        console.log("🚀 -> nuxtApp.hook -> 'app:error: -- start");

        const device = useDevice();

        const errorData = {
            ...(process.client ? error.toJSON() : { message: error.toString(), statusCode: 500 }),
            // path:nuxtApp.payload.path,
            fullPath: nuxtApp._route.fullPath,
            data: nuxtApp.payload.error?.data,
            serverRendered: process.client ? false : true,
            device: device.isMobile ? "mobile" : "desktop",
            userAgent: device.userAgent,
        };

        useFetch("/api/push-message", {
            method: "POST",
            body: {
                msgtype: "markdown",
                markdown: {
                    content: [
                        "## 😈NearHub Website Error😈",
                        `Message: ${errorData.message}` + (process.env.NODE_ENV === "development" ? "[开发环境]" : ""),
                        `StatusCode: ${errorData.statusCode}`,
                        `Path: ${errorData.fullPath}`,
                        `Server Rendered: ${errorData.serverRendered}`,
                        `Data: ${errorData.data}`,
                        `Device: ${errorData.device}`,
                        `User Agent: ${errorData.userAgent}`,
                        process.server ? getMemTotal().content : "",
                        `Occur Date: ${new Date().toLocaleString()}`,
                    ].join("\n"),
                },
            },
        });

        // axios
        //     .post("/api/push-message", {
        //         msgtype: "markdown",
        //         markdown: {
        //             content: [
        //                 "## 😈NearHub Website Error😈",
        //                 `Message: ${errorData.message}` + (process.env.NODE_ENV === "development" ? "[开发环境]" : ""),
        //                 `StatusCode: ${errorData.statusCode}`,
        //                 `Path: ${errorData.fullPath}`,
        //                 `Server Rendered: ${errorData.serverRendered}`,
        //                 `Data: ${errorData.data}`,
        //                 `Device: ${errorData.device}`,
        //                 `User Agent: ${errorData.userAgent}`,
        //                 process.server ? getMemTotal().content : "",
        //             ].join("\n"),
        //         },
        //     })
        //     .catch((res) => {
        //         console.log("🚀 -> nuxtApp.hook -> res:", res);
        //     });

        console.log("🚀 -> nuxtApp.hook -> errorData:", errorData);
        console.log("🚀 -> nuxtApp.hook -> 'app:error: -- end");
    });
});
